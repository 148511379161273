import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Commadgrid from "./Commandgrid";

class Commandprete extends Component 
{

    componentDidMount() {
        if (typeof this.props.checkApiData === 'function') {
            this.props.checkApiData();
        }
        if (typeof this.props.emptyCart === "function" ) {
            this.props.emptyCart();
        }
    }

    loadCommand = (cmd) => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "get-command", cmd.id);
        }
    }

    showCommandEtat = (cmd) => {
        if (typeof this.props.showCommandEtat === 'function') {
            this.props.showCommandEtat(1, cmd);
        }
    }

    render() {
        const commands = this.props.commandesPrete || [];
        return (
            <React.Fragment>
                <h4 className="home-title">- Commandes prête</h4>
                <Commadgrid commands={commands} loadCommand={this.loadCommand} showCommandEtat={this.showCommandEtat} />
            </React.Fragment>
        );
    }
}

export default withRouter(Commandprete);
