import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import InputMask from 'react-input-mask';
import { api_url, getEtatLibelle } from '../helpers/cartHelper';
import { config } from '../helpers/configHelper';

class Journal extends Component {

    constructor(props) {
        super(props);
        const today = new Date();
        this.state = {
            auth_user: this.props.auth_user,
            date: (("0" + today.getDate()).slice(-2) + "-" + ("0" + (today.getMonth() + 1)).slice(-2) + "-" + today.getFullYear()),
            etat: "all",
            commands: [],
            total: 0,
            payment_methods: config.payment_methods,
        }
    }

    componentDidMount() {
        this.getCommands();
    }

    changeDateHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ date: val });
    }

    getCommands = () => {
        const user = this.state.auth_user.user;
        const params = {
            "date": this.state.date,
            "etat": (this.state.etat === 'all' ? null : this.state.etat),
            "payment_method": this.state.payment_method,
            "delivery_method": this.state.livraison,
        };
        let url_params = encodeURIComponent(JSON.stringify(params));
        fetch(api_url + `get-commands/${url_params}?api_token=` + user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else if (response.commands) {
                    this.setState({
                        commands: response.commands,
                        total: response.total
                    });
                }
            });
    }

    getActiveEtat = (etat) => { return this.state.etat === etat ? " active" : ""; }
    setActiveEtat = (etat) => {
        let new_etat = etat === this.state.etat ? "" : etat;
        this.setState({ etat: new_etat });
    }

    getActiveMethodPayment = (meth) => { return this.state.payment_method === meth ? " active" : ""; }
    setActiveMethodPayment = (meth) => {
        let new_meth = meth === this.state.payment_method ? "" : meth;
        this.setState({ payment_method: new_meth });
    }

    getActiveLivraison = (liv) => { return this.state.livraison === liv ? " active" : ""; }
    setActiveLivraison = (liv) => {
        let new_liv = liv === this.state.livraison ? "" : liv;
        this.setState({ livraison: new_liv });
    }

    loadCommand = (cmd) => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "get-command", cmd.id);
        }
    }

    render() {

        const delivery_method = this.state.auth_user.restaurant.delivery_method;

        return (
            <React.Fragment>
                <div className="row form-group">
                    <div className="col-sm-3">
                        <InputMask
                            className="form-control"
                            mask="99-99-9999"
                            onChange={this.changeDateHandling}
                            onClick={(event) => this.props.showKeyboard(event, this.changeDateHandling)}
                            value={this.state.date}
                            placeholder="jj-mm-aaaa"
                        />
                    </div>
                    <div className="col-sm-9">
                        <div className="btn-group btn-group-etat">
                            {delivery_method.map((liv) =>
                                <button type="button" key={liv.id} className={`btn btn-light${this.getActiveLivraison(liv.title)}`} onClick={() => this.setActiveLivraison(liv.title)}>{liv.title}</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-12">
                        <div className="btn-group btn-group-etat">
                            {this.state.payment_methods.map(method =>
                                <button type="button" key={method} className={`btn btn-light${this.getActiveMethodPayment(method)}`} onClick={() => this.setActiveMethodPayment(method)}>{method}</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-12">
                        <div className="btn-group btn-group-etat">
                            <button type="button" className={`btn btn-light${this.getActiveEtat('all')}`} onClick={() => this.setActiveEtat("all")}>Tout</button>
                            <button type="button" className={`btn btn-light${this.getActiveEtat('progress')}`} onClick={() => this.setActiveEtat("progress")}>En cours</button>
                            <button type="button" className={`btn btn-light${this.getActiveEtat('ready')}`} onClick={() => this.setActiveEtat("ready")}>Prête</button>
                            <button type="button" className={`btn btn-light${this.getActiveEtat('delivery')}`} onClick={() => this.setActiveEtat("delivery")}>En livraison</button>
                            <button type="button" className={`btn btn-light${this.getActiveEtat('completed')}`} onClick={() => this.setActiveEtat("completed")}>Terminées</button>
                            <button type="button" className={`btn btn-light${this.getActiveEtat('cancel')}`} onClick={() => this.setActiveEtat("cancel")}>Annulées</button>
                        </div>
                    </div>
                </div>
                <div className="clarfix">
                    <button className="btn btn-primary form-control bg-dark" onClick={() => this.getCommands()}><img className="icon-arrow-right" src={require('../../assets/images/left-arrow-white.svg')} alt="" height="25" /></button>
                </div>

                <hr />
                <h4 className="text-danger font-weight-bold">TOTAL: {parseFloat(this.state.total).toFixed(2)}€</h4>
                <table className="table">
                    <thead>
                        <tr>
                            <th>N°</th>
                            <th>Total</th>
                            <th>Méthode de livraison</th>
                            <th>Méthode de paiement</th>
                            <th>Etat</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.commands.map(cmd =>
                            <tr key={Math.random()}>
                                <td><div className="btn btn-secondary" onClick={() => { this.loadCommand(cmd); }}>{cmd.id}</div></td>
                                <td>{cmd.refund ? (parseFloat(cmd.refund) * -1) : parseFloat(cmd.total)}€</td>
                                <td>{cmd.delivery_name}</td>
                                <td>{cmd.mode_payement}</td>
                                <td>{getEtatLibelle(cmd.order_statut)}</td>
                                <td>
                                    <Link to={`/facture/${cmd.id}`} key={cmd.id} className="btn btn-secondary btn-sm">Facture</Link>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default withRouter(Journal);
