import React, { Component } from 'react';
import Commadgrid from "./Commandgrid";

class Commandencours extends Component {

    componentDidMount() {
        if (typeof this.props.checkApiData === 'function') {
            this.props.checkApiData();
        }
        if (typeof this.props.emptyCart === "function") {
            this.props.emptyCart();
        }
    }

    loadCommand = (cmd) => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "get-command", cmd.id);
        }
    }

    showCommandEtat = (cmd) => {
        if (typeof this.props.showCommandEtat === 'function') {
            this.props.showCommandEtat(1, cmd);
        }
    }

    render() {
        const commands = this.props.commandesEnCours || [];
        return (
            <React.Fragment>
                <h4 className="home-title">- Commandes en cours</h4>
                <Commadgrid
                    commands={commands}
                    loadCommand={this.loadCommand}
                    showCommandEtat={this.showCommandEtat}
                />
            </React.Fragment>
        );
    }
}

export default (Commandencours);
