import React, { Component } from 'react';
import './auth.css';
import { withRouter } from "react-router-dom";
import {authUser, api_url_login} from '../helpers/cartHelper';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            login: "",
            password: "",
            error_message: null
        };
        this.child = React.createRef();
    }

    changeLoginHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ login: val });
    }

    changePasswordHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ password: val });
    }

    showKeyboard = (event, handle) => {
        if (typeof this.props.showKeyboard === 'function') {
            this.props.showKeyboard(1, handle, event.target);
        }
    }

    authenticate = () => {
        const login = this.state.login;
        const password = this.state.password;
        if ( login && password ) {
            let credentials = encodeURIComponent(JSON.stringify({"login": login, "password": password}));
            fetch(api_url_login+`/${credentials}`)
            .then(results => { return results.json(); })
            .then(response => {
                if ( response.error ) {
                    this.setState({ error_message: response.message });
                } else {
                    this.setState({ error_message: null }, function(){
                        authUser().persist(response);
                        if (typeof this.props.authenticate === "function") {
                            this.props.authenticate();
                        }
                        localStorage.removeItem("superRestoApiListing");
                        this.props.history.push(`/`);
                    });
                }
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="app-login">
                    <div id="app-login-container">
                        <div className="login">

                            { this.state.error_message ? (
                                <div className="alert alert-danger">{this.state.error_message}</div>
                            ) : "" }
                            
                            <div className="form-group">
                                <label>Login</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={this.state.login} 
                                    onChange={(event) => this.changeLoginHandling(event)} 
                                    onClick={(event) =>this.showKeyboard(event, this.changeLoginHandling)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Mot de passe</label>
                                <input 
                                    type="password" 
                                    className="form-control" 
                                    value={this.state.password} 
                                    onChange={(event) => this.changePasswordHandling(event)} 
                                    onClick={(event) =>this.showKeyboard(event, this.changePasswordHandling)}
                                />
                            </div>
                            <hr/>
                            <button className="btn btn-payment text-center" onClick={() => this.authenticate()}>
                                Se connecter
                                <span className="right"><img className="icon-arrow-right" src={require("../../assets/images/left-arrow-white.svg")} alt="" height="25" /></span>
                            </button>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Login);
