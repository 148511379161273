import React, { Component } from 'react';
import { config } from '../helpers/configHelper';
import { api_url } from "../helpers/cartHelper";

class Facture extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            auth_user: props.auth_user.user,
            seller: props.auth_user.restaurant,
            client: {},
            fields: config.facture_client_fields,
            active_field: null,
            command_id: props.id,
            facture: null
        };
    }

    componentDidMount() {
        fetch(api_url + `get-facture/${this.state.command_id}?api_token=${this.state.auth_user.api_token}`)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.facture) {
                    this.setState({
                        facture: data.facture
                    });
                    return;
                } else if (data.client) {
                    const fields = this.state.fields;
                    fields.forEach(field => {
                        if (data.client[field.name]) {
                            field.value = data.client[field.name];
                        }
                    });
                    this.setState({
                        client: data.client,
                        fields: fields
                    });
                    return;
                }
            });

        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "get-command", this.state.command_id);
        }
    }

    componentWillUnmount() {

    }

    generate = () => {
        const fields = this.state.fields;
        let isValid = true;

        let data = {};
        fields.forEach(field => {
            field.classError = null;
            if (field.required && !field.value) {
                field.classError = "is-invalid";
                isValid = false;
            }
            if (field.value) {
                data[field.name] = field.value;
            }
        });
        data['order_id'] = this.state.command_id;

        this.setState({ fields: fields });

        if (isValid) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
            fetch(api_url + `factures/save?api_token=${this.state.auth_user.api_token}`, requestOptions)
                .then(results => { return results.json(); })
                .then(data => {
                    window.location.reload();
                });
        }
    }

    handleChange = (event, inp = null) => {
        const field = inp ? inp : this.state.active_field;

        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        const fields = this.state.fields;

        let index = fields.findIndex(item => item.name === field);
        let row = fields[index];
        row.value = val;

        this.setState({ fields: fields });
    }

    showKeyboard = (event, handle = null) => {
        let target = event.target;
        this.setState({ active_field: target.name }, function () {
            if (typeof this.props.showKeyboard === 'function') {
                this.props.showKeyboard(1, handle, target);
            }
        });
    }

    render() {
        const fields = this.state.fields;
        return (
            <React.Fragment>
                <h3 className="home-title">Facture</h3>
                <hr />
                {this.state.facture ? (
                    <div>
                        <a href={this.state.facture.file} target="_blank" rel="noopener noreferrer" className="btn btn-payment text-center">
                            Télécharger la facture N°{this.state.facture.id}
                        </a>
                    </div>
                ) : (
                    <div className='row'>
                        <div className='col-md-8'>
                            <h4 className="home-title">L'identité du client</h4>
                            <hr />
                            <div className='row'>
                                {fields.map(field =>
                                    <div className={`form-group ${field.class ? field.class : 'col-sm-12'}`} key={field.label}>
                                        <label>{field.label} <small className="text-danger">{field.required ? "*" : ""}</small></label>
                                        <input
                                            type={field.type}
                                            value={field.value}
                                            className={`form-control ${field.classError}`}
                                            name={field.name}
                                            required={field.required}
                                            placeholder={`${field.placeholder ? field.placeholder : ''}`}
                                            onChange={(event) => this.handleChange(event, field.name)}
                                            onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                        />
                                    </div>
                                )}
                                <div className="col-md-12">
                                    <br />
                                    <button type="button" className="btn btn-payment text-center" onClick={() => this.generate()}>
                                        Générer
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <h4 className="home-title">L'identité du vendeur</h4>
                            <hr />
                            {this.state.seller.social_reason} <br />
                            {this.state.seller.address} <br />
                            {this.state.seller.postal_code} {this.state.seller.locality} <br />
                            Tél: {this.state.seller.phone} <br />
                            Mob: {this.state.seller.mobile} <br />
                            E-mail: {this.state.seller.email} <br />
                            Siret: {this.state.seller.siret} <br />
                        </div>
                    </div>
                )
                }
            </React.Fragment >
        );
    }
}

export default (Facture);
