import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { api_url, getEtatLibelle, dateFormat } from '../helpers/cartHelper';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user.user,
            num_command: "",
            etat: "",
            date: "",
            date_from: "",
            date_to: "",
            nom_client: "",
            commands: []
        }
    }

    changeNumCommandHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ num_command: val });
    }

    changeDateDuCommandHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ date_from: val });
    }

    changeDateAuCommandHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ date_to: val });
    }

    changeNomClientCommandHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ nom_client: val });
    }

    showKeyboard = (event, handle = null) => {
        if (typeof this.props.showKeyboard === 'function') {
            this.props.showKeyboard(1, handle, event.target);
        }
    }

    getActiveDate = (dat) => { return this.state.date === dat ? ' active' : ''; }

    setActiveDate = (date) => {
        let new_etat = date === this.state.date ? "" : date;
        this.setState({ date: new_etat });
    }

    getActiveEtat = (etat) => { return this.state.etat === etat ? " active" : ""; }

    setActiveEtat = (etat) => {
        let new_etat = etat === this.state.etat ? "" : etat;
        this.setState({ etat: new_etat });
    }

    search = () => {
        const user = this.state.auth_user;
        const params = {
            "num": this.state.num_command,
            "etat": this.state.etat,
            "date_type": this.state.date,
            "date_from": this.state.date_from,
            "date_to": this.state.date_to,
        };
        if (!params.num && !params.etat && !params.date_type && !params.date_from && !params.date_to) return false;
        let url_params = encodeURIComponent(JSON.stringify(params));
        fetch(api_url + `get-commands/${url_params}?api_token=` + user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else if (response.commands) {
                    this.setState({
                        commands: response.commands,
                    });
                }
            });

    }

    showForm = () => {
        this.setState({ commands: [] });
    }

    render() {
        const commands = this.state.commands;

        if (!commands.length) {
            return (
                <React.Fragment>
                    <fieldset className="custom-fieldset">
                        {/* <legend>- Par commande</legend> */}
                        <div className="row">
                            <div className="form-group col-sm-2">
                                <label>N°</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={this.state.num_command}
                                    onChange={this.changeNumCommandHandling}
                                    onClick={(event) => this.showKeyboard(event, this.changeNumCommandHandling)}
                                />
                            </div>
                            <div className="form-group col-sm-10">
                                <label>Etat de la commande</label>
                                <div className="btn-group btn-group-etat">
                                    <button type="button" className={`btn btn-light${this.getActiveEtat('waiting')}`} onClick={() => this.setActiveEtat("waiting")}>À valider</button>
                                    <button type="button" className={`btn btn-light${this.getActiveEtat('progress')}`} onClick={() => this.setActiveEtat("progress")}>En cours</button>
                                    <button type="button" className={`btn btn-light${this.getActiveEtat('ready')}`} onClick={() => this.setActiveEtat("ready")}>Prête</button>
                                    <button type="button" className={`btn btn-light${this.getActiveEtat('delivery')}`} onClick={() => this.setActiveEtat("delivery")}>En livraison</button>
                                    <button type="button" className={`btn btn-light${this.getActiveEtat('completed')}`} onClick={() => this.setActiveEtat("completed")}>Terminée</button>
                                    <button type="button" className={`btn btn-light${this.getActiveEtat('cancel')}`} onClick={() => this.setActiveEtat("cancel")}>Annulée</button>
                                </div>
                            </div>

                            <div className="form-group col-sm-12">
                                <label>Date</label>
                                <div className="btn-group btn-group-etat">
                                    <button type="button" className={`btn btn-light${this.getActiveDate('day')}`} onClick={() => this.setActiveDate("day")}>Aujourd'hui</button>
                                    <button type="button" className={`btn btn-light${this.getActiveDate('yesterday')}`} onClick={() => this.setActiveDate("yesterday")}>Hier</button>
                                    <button type="button" className={`btn btn-light${this.getActiveDate('week')}`} onClick={() => this.setActiveDate("week")}>Cette semaine</button>
                                    <button type="button" className={`btn btn-light${this.getActiveDate('month')}`} onClick={() => this.setActiveDate("month")}>Ce mois</button>
                                    <button type="button" className={`btn btn-light${this.getActiveDate('year')}`} onClick={() => this.setActiveDate("year")}>Cette année</button>
                                    <button type="button" className={`btn btn-light${this.getActiveDate('custom')}`} onClick={() => this.setActiveDate("custom")}>Date personnalisée</button>
                                </div>
                            </div>

                            <div className="col-sm-12">
                                {this.state.date === "custom" ? (
                                    <div className="row">
                                        <div className="form-group col-sm-6">
                                            <label>Date du</label>
                                            <InputMask
                                                type="text"
                                                className="form-control"
                                                mask="99-99-9999"
                                                value={this.state.date_from}
                                                onChange={this.changeDateDuCommandHandling}
                                                onClick={(event) => this.showKeyboard(event, this.changeDateDuCommandHandling)}
                                                placeholder="jj-mm-aaaa"
                                            />
                                        </div>
                                        <div className="form-group col-sm-6">
                                            <label>Date au</label>
                                            <InputMask
                                                type="text"
                                                className="form-control"
                                                mask="99-99-9999"
                                                value={this.state.date_to}
                                                onChange={this.changeDateAuCommandHandling}
                                                onClick={(event) => this.showKeyboard(event, this.changeDateAuCommandHandling)}
                                                placeholder="jj-mm-aaaa"
                                            />
                                        </div>
                                    </div>
                                ) : ""}
                            </div>
                        </div>
                    </fieldset>
                    <div className="clearfix"><hr />
                        <button type="button" className="btn btn-payment text-center" onClick={() => this.search()}>
                            Rechercher
                            <span className="right"><img src={require("../../assets/images/search.svg")} alt="" height="25" /></span>
                        </button>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="list-btns">
                        <div className="btn-app btn-app-auto" onClick={() => this.showForm()}>
                            <div className="img-container">
                                <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="" />
                            </div>
                        </div>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>N°</th>
                                <th>Total</th>
                                <th>Etat</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commands.map(cmd =>
                                <tr key={Math.random()}>
                                    <td>{cmd.id}</td>
                                    <td>{parseFloat(cmd.total).toFixed(2)}€</td>
                                    <td>{getEtatLibelle(cmd.order_statut)}</td>
                                    <td>{dateFormat(cmd.date)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </React.Fragment>
            );
        }
    }
}

export default Search;
