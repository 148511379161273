import React, { Component } from 'react';
import './categories.css';
import { Link } from "react-router-dom";
import moment from 'moment';
import { api_url, paginate, apiListing } from "../helpers/cartHelper";

class Categories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            categories_paginate: [],
            next_button: false,
            prev_button: false,
            auth_user: props.auth_user.user,
            products: [],
            search_value: '',
            product_added_at: null
        };
    }

    componentDidMount() {
        let listing = apiListing().get() || {};
        let auth_user = this.state.auth_user;
        if (!listing.categories) {
            fetch(api_url + "categories?api_token=" + auth_user.api_token)
                .then(results => { return results.json(); })
                .then(response => {
                    if (response === "Unauthorized") {
                        if (typeof this.props.logout === 'function') {
                            this.props.logout();
                        }
                    } else {
                        const paginate_func = paginate(response);
                        this.setState({
                            categories: response,
                            categories_paginate: paginate_func.data,
                            next_button: paginate_func.next_button
                        }, function () {
                            listing.categories = response;
                            apiListing().persist(listing);
                        });
                    }
                });
        } else {
            const categories = listing.categories;
            const paginate_func = paginate(categories);
            this.setState({
                categories: categories,
                categories_paginate: paginate_func.data,
                next_button: paginate_func.next_button
            });
        }
    }

    getCategories = (nav) => {
        const paginate_func = paginate(this.state.categories, this.state.categories_paginate, nav);
        this.setState({
            categories_paginate: paginate_func.data,
            next_button: paginate_func.next_button,
            prev_button: paginate_func.prev_button
        });
    }

    showKeyboard = (event, handle = null) => {
        if (typeof this.props.showKeyboard === 'function') {
            // this.props.showKeyboard(1, handle, event.target);
        }
    }

    addProduct = (product) => {
        product.uid = Math.floor(Math.random() * (+new Date()) * parseFloat(this.state.auth_user.id));
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(product, 'add');
        }
    }

    changeSearchHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ search_value: val }, function () {
            if (val) {

                if (this.state.product_added_at) {
                    let startTime = moment(this.state.product_added_at);
                    let endTime = moment(moment().toDate().getTime());
                    let diffDuration = moment.duration(endTime.diff(startTime));
                    if (diffDuration.seconds() < 3) return;
                }

                const auth_user = this.state.auth_user;
                fetch(api_url + `products/search/${val}?api_token=${auth_user.api_token}`)
                    .then(results => { return results.json(); })
                    .then(data => {
                        this.setState({
                            products: data.products,
                        }, function () {
                            if (this.state.products.length === 1) {
                                let prd = this.state.products[0];
                                if (prd.code === val) {
                                    this.addProduct(prd);
                                    this.setState({
                                        product_added_at: moment().toDate().getTime(),
                                        search_value: '',
                                    });

                                }
                            }
                        });
                    });
            } else {
                this.setState({ products: [] });
            }
        });
    }

    render() {
        const categories = this.state.categories_paginate;
        return (
            <React.Fragment>

                <div className='mb-4'>
                    <input
                        type="text"
                        className="form-control border"
                        value={this.state.search_value}
                        placeholder="Recherche..."
                        onChange={this.changeSearchHandling}
                        onClick={(event) => this.showKeyboard(event, this.changeSearchHandling)}
                        autoFocus
                    />
                </div>

                {this.state.search_value ? (
                    <div className="list-btns">

                        <Link to="/" className="btn-app btn-app-auto">
                            <div className="img-container">
                                <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="Prev" />
                            </div>
                        </Link>

                        {this.state.products.map(product =>
                            <div className={`btn-app`} key={Math.random()} onClick={() => this.addProduct(product)}>
                                <div className="img-container">
                                    {product.name} <br />{product.variant}
                                </div>
                                <h4 className="btn-title item-price">{product.price}€</h4>
                            </div>
                        )}
                    </div>

                ) : (
                    <div className={`list-btns`}>

                        {(this.state.prev_button) ? (
                            <div className="btn-app btn-app-auto">
                                <div className="img-container" onClick={() => this.getCategories('prev')}>
                                    <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="Prev" />
                                </div>
                            </div>
                        ) : (
                            <Link to="/" className="btn-app btn-app-auto">
                                <div className="img-container">
                                    <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="Prev" />
                                </div>
                            </Link>
                        )}

                        {categories.map((category) =>
                            <Link to={`/category/${category.id}`} className={`btn-app`} key={category.id} style={{ backgroundColor: category.color, color: category.font_color }}>
                                <div className="img-container">
                                    <img className="icon" src={category.image} alt="" />
                                </div>
                                <h4 className="btn-title">{category.name}</h4>
                            </Link>
                        )}

                        {(this.state.next_button) ? (
                            <div className="btn-app btn-app-auto">
                                <div className="img-container" onClick={() => this.getCategories('next')}>
                                    <img className="icon icon-next" src={require("../../assets/images/left-arrow.svg")} alt="Next" />
                                </div>
                            </div>
                        ) : ""}

                    </div>
                )}


            </React.Fragment>
        );
    }
}

export default Categories;
