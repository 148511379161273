import React, { Component } from 'react';
import { api_url } from '../helpers/cartHelper';

class Support extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            open_ticket: 0,
            type_ticket: "",
            sujet_ticket: ""
        };
    }

    changeTypeHandling = (event) => {
        let value = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ type_ticket: value });
    }

    changeSujetHandling = (event) => {
        let value = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ sujet_ticket: value });
    }

    openTicket = (val=1) => { this.setState({ open_ticket: val }); }

    sendTicket = () => {
        const user = this.props.auth_user;
        const type_ticket = this.state.type_ticket;
        const sujet_ticket = this.state.sujet_ticket;
        if ( type_ticket && sujet_ticket ) {
            let params = {
                "type_ticket": type_ticket, 
                "sujet_ticket": sujet_ticket, 
            };
            let url_params = encodeURIComponent(JSON.stringify(params));
            //console.log(api_url+`send-message/${url_params}?api_token=`+user.api_token); return false;
            fetch(api_url+`send-ticket/${url_params}?api_token=`+user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if ( response === "Unauthorized" ) {
                    this.logout();
                } else if ( response.tickets ) {
                    this.setState({
                        open_ticket: 0,
                    }, function(){
                        if (typeof this.props.reloadListTickets === 'function') {
                            this.props.reloadListTickets(response.tickets);
                        }
                        
                    });
                }
            });
        }
    }

    render() {
        const tickets = this.props.tickets;

        if ( this.state.open_ticket === 0 ) {
            return (
                <fieldset className="pop-fieldset">
                    <legend>Support</legend>
                    <div className="form-group text-right">
                        <button className="btn" onClick={() => this.openTicket()}>Ouvrir un ticket</button>
                    </div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Sujet</th>
                                <th>Statut</th>
                                <th>Messages non lus</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tickets.map(ticket => 
                                <tr key={Math.random()} className={ticket.view===this.props.view?"":"d-none"}>
                                    <td>{ ticket.num }</td>
                                    <td>{ ticket.subject }</td>
                                    <td>{ ticket.statut }</td>
                                    <td>{ ticket.nbr_messages }</td>
                                    <td>
                                        <button type="button" className="btn btn-primary form-control bg-dark" onClick={()=>this.props.showMessage('ticket', ticket)}>Voir</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </fieldset>
            );
        } else {
            return (
                <fieldset className="pop-fieldset">
                    <legend>Ouvrir un ticket</legend>
                    <div className="text-right">
                        <button className="btn" onClick={() => this.openTicket(0)}>Liste des tickets</button>
                    </div>
                    <div className="clearfix">
                        <div className="form-group">
                            <label>Type de ticket</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                value={this.state.type_ticket} 
                                onChange={this.changeTypeHandling} 
                                onClick={(event) => this.props.showKeyboard(event, this.changeTypeHandling)} 
                            />
                        </div>
                        <div className="form-group">
                            <label>Sujet de ticket</label>
                            <textarea 
                                value={this.state.sujet_ticket} 
                                onChange={this.changeSujetHandling} 
                                onClick={(event) => this.props.showKeyboard(event, this.changeSujetHandling)} 
                                className="form-control" 
                                rows="4" 
                            ></textarea>
                        </div>
                        <button type="button" className="btn btn-payment text-center" onClick={()=>this.sendTicket()}>
                            Envoyer
                            <span className="right"><img className="icon-arrow-right" src={require("../../assets/images/left-arrow-white.svg")} alt="" height="25" /></span>
                        </button>
                    </div>
                </fieldset>
            );
        }
    }
}

export default Support;
