import React, { Component } from 'react';

class Ingredients extends Component 
{
    updateIngredient(ingredient) {
        if (typeof this.props.updateIngredient === 'function') {
            this.props.updateIngredient(ingredient);
        }
        // let ingredientInCart = false;
        // product.ingredients.forEach(ing => {
        //     if ( ingredient.ingredient_id === ing.ingredient_id ) {
        //         const ing_index = product.ingredients.findIndex(ing => (ing.id === ingredient.id));
        //         product.ingredients.splice(ing_index, 1);
        //         ingredientInCart = true;
        //     }
        // });
        // if ( !ingredientInCart ) product.ingredients.push(ingredient);

        // if (typeof this.props.reloadCart === 'function') {
        //     this.props.reloadCart(product, 'add');
        // }
    }

    paginateIngredients = (ingredients, nav) => {
        if (typeof this.props.paginateIngredients === 'function') {
            this.props.paginateIngredients(ingredients, nav);
        }
    }

    render() {
        const ingredients = this.props.ingredients;
        
        return (
            <React.Fragment>
                <fieldset className="pop-fieldset"  >
                    <legend>{this.props.category}</legend>
                    <div className="list-btns">

                        { (this.props.prev_button) ? (
                            <div className="btn-app btn-app-auto">
                                <div className="img-container" onClick={() => this.paginateIngredients(ingredients, 'prev')}>
                                    <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="Prev" />
                                </div>
                            </div>
                        ) : "" }

                        { ingredients.map(ingredient =>
                            <div className={`btn-app ${ this.props.product.ingredients.find(ing => ing.title === ingredient.title) ? 'active' : '' }`} key={Math.random()} onClick={() => this.updateIngredient(ingredient)}>
                                <div className="img-container">{ingredient.title}</div>
                                <h4 className="btn-title item-price">{ingredient.price}€</h4>
                            </div>
                        )}

                        { (this.props.next_button) ? (
                            <div className="btn-app btn-app-auto">
                                <div className="img-container" onClick={() => this.paginateIngredients(ingredients, 'next')}>
                                    <img className="icon icon-next" src={require("../../assets/images/left-arrow.svg")} alt="Next" />
                                </div>
                            </div>
                        ) : "" }

                    </div>
                </fieldset>
            </React.Fragment>
        );
    }

}

export default Ingredients;
