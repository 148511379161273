import React, { Component } from 'react';
import './App.css';
import { HashRouter, Route } from "react-router-dom";
import Header from './components/header/Header';
import Home from './components/home/Home';
import Categories from './components/categories/Categories';
import Category from './components/categories/Category';
import Payment from './components/payment/Payment';
import Login from './components/auth/Login';
import { authUser, api_url, loadCart, logout, clearCart } from './components/helpers/cartHelper';
import Commandattente from './components/command/Commandattente';
import Commandencours from './components/command/Commandencours';
import Commandterminees from './components/command/Commandterminees';
import Sidebar from './components/sidebar/Sidebar';
import Livraison from './components/home/Livraison';
import Confirmcommand from './components/home/Confirmcommand';
import Client from './components/home/Client';
import Keyboard from './components/sidebar/Keyboard';
import Personnaliser from './components/personnaliser/Personnaliser';
import Reduction from './components/sidebar/Reduction';
import Search from './components/search/Search';
import Commandavalider from './components/command/Commandavalider';
import Commandprete from './components/command/Commandprete';
import Commandenlivraison from './components/command/Commandenlivraison';
import Notifications, { notify } from 'react-notify-toast';
import Journal from './components/stats/Journal';
import Commandetat from './components/command/Commandetat';
import Commandannulees from './components/command/Commandannulees';
import Facture from './components/command/Facture';
import Messages from './components/message/Messages';
import NewProduct from './components/products/NewProduct';

class App extends Component {

    constructor(props) {
        //localStorage.clear();
        //console.log(authUser());
        //clearCart();
        super(props);
        this.state = {
            auth_user: (authUser().get() || {}),
            modal_show: 0,
            modal_component: null,
            isCustomisationModal: false,
            cartItems: (loadCart().get() || {}),
            keyboard_show: 0,
            keyboardHandling: null,
            keyboard_input: null,
            type_livraison: null,
            lastProduct: {}
        };

        this.child = React.createRef();

        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleSidebar = this.toggleSidebar.bind(this);
    }

    componentDidMount() {
        const auth_user = this.state.auth_user.user || {};
        if (auth_user.id) {
            this.checkApiData();
            setInterval(this.checkApiData, 60000);
        } else {
            this.logout();
        }


        this.setLastProduct();
    }

    showNotification = (messge, type = "info") => {
        notify.show(messge, type, 2000);
    }

    toggleSidebar(toggle) {
        let sidebar = document.getElementById("sidebar");
        if (sidebar.classList.contains("is-visible") || toggle === false) {
            sidebar.classList.remove("is-visible");
            this.resetHash();
        }
        else {
            sidebar.classList.add("is-visible");
            this.resetHash(window.location.hash);
        }
    }

    toggleMenu(toggle) {
        let header = document.getElementById("header");
        if (header.classList.contains("is-visible") || toggle === false) {
            header.classList.remove("is-visible");
            this.resetHash();
        }
        else {
            header.classList.add("is-visible");
            this.resetHash(window.location.hash);
        }
    }

    resetHash(lastHash) {
        if (lastHash) {
            window.onhashchange = () => {
                window.location.hash = lastHash;
                //this.toggleMenu(false);
                //this.toggleSidebar(false);
            };
        }
        else {
            window.onhashchange = null;
        }
    }

    render() {
        const auth_user = this.state.auth_user ? this.state.auth_user.user : {};
        if (auth_user && auth_user.id) {
            return (
                <HashRouter>
                    <div className="app container-fluid p-0">
                        <div id="sidebar">
                            <Sidebar
                                cartItems={this.state.cartItems}
                                reloadCart={this.reloadCart}
                                setModal={this.setModal}
                                personalizeProduct={this.personalizeProduct}
                                ref={this.child}
                                showNotification={this.showNotification}
                                auth_user={this.state.auth_user}
                                lastProduct={this.state.lastProduct}
                                resetHash={this.resetHash}
                                toggleSidebar={this.toggleSidebar}
                            />
                            {this.state.keyboard_show ? (
                                <Keyboard
                                    showKeyboard={this.showKeyboard}
                                    keyboardHandling={this.state.keyboardHandling}
                                    input={this.state.keyboard_input}
                                    ref={this.child}
                                />
                            ) : ""}
                            {this.state.etat_command_show ? (
                                <Commandetat
                                    showCommandEtat={this.showCommandEtat}
                                    command_selected={this.state.command_selected}
                                    changeEtatCommand={this.changeEtatCommand}
                                    ref={this.child}
                                />
                            ) : ""}
                            <div className="mobile-toggle-buttons">
                                <span onClick={this.toggleSidebar}><img src={require('./assets/images/x.svg')} alt="" style={{ height: '16px' }} /></span>
                            </div>
                        </div>
                        <div id="main">
                            <div id="header">
                                <Header
                                    commandesEnAttente={this.state.commandesEnAttente}
                                    commandesEnCours={this.state.commandesEnCours}
                                    commandesAvalider={this.state.commandesAvalider}
                                    commandesTerminees={this.state.commandesTerminees}
                                    commandesPrete={this.state.commandesPrete}
                                    commandesEnLivraison={this.state.commandesEnLivraison}
                                    commandesAnnulees={this.state.commandesAnnulees}
                                    commandesReclamations={this.state.commandesReclamations}
                                    ticketsMessages={this.state.ticketsMessages}
                                    contactMessages={this.state.contactMessages}
                                    resetHash={this.resetHash}
                                    toggleMenu={this.toggleMenu}
                                    setTypeLivraison={this.setTypeLivraison}
                                />
                                <div className="mobile-categories">
                                    <Categories
                                        auth_user={this.state.auth_user}
                                        logout={this.logout}
                                        showKeyboard={this.showKeyboard}
                                    />
                                </div>
                                <div className="mobile-toggle-buttons menu-toggle">
                                    <span onClick={this.toggleMenu}><img src={require('./assets/images/x-dark.svg')} alt="" style={{ height: '16px' }} /></span>
                                </div>
                                <div className="mobile-toggle-buttons">
                                    <span className="last-prod-actions-toggle"><img src={require('./assets/images/fire.svg')} alt="" style={{ height: '21px' }} /></span>
                                    <span className="cart-toggle" onClick={this.toggleSidebar}><span>{parseFloat(this.state.cartItems.total || '0').toFixed(2)}€</span><img src={require('./assets/images/cart.svg')} alt="Panier" style={{ height: '20px' }} /></span>
                                    <span className="menu-toggle" onClick={this.toggleMenu}><img src={require('./assets/images/menu.svg')} alt="Menu" style={{ height: '28px' }} /></span>
                                </div>
                            </div>
                            <div id="content">
                                <Notifications options={{ top: '80px', zIndex: 999999 }} />
                                <Route exact path="/" render={() =>
                                    <Home
                                        checkApiData={this.checkApiData}
                                        setModal={this.setModal}
                                        setTypeLivraison={this.setTypeLivraison}
                                        reloadCart={this.reloadCart}
                                        logout={this.logout}
                                        emptyCart={this.emptyCart}
                                        auth_user={this.state.auth_user}
                                    />
                                } />
                                <Route exact path="/categories" render={() =>
                                    <Categories
                                        auth_user={this.state.auth_user}
                                        logout={this.logout}
                                        showKeyboard={this.showKeyboard}
                                        reloadCart={this.reloadCart}
                                    />
                                } />
                                <Route exact path="/category/:id" render={(props) =>
                                    <Category
                                        id={props.match.params.id}
                                        reloadCart={this.reloadCart}
                                        personalizeProduct={this.personalizeProduct}
                                        auth_user={this.state.auth_user}
                                        logout={this.logout}
                                    />
                                } />
                                <Route exact path="/payment" render={() =>
                                    <Payment
                                        setModal={this.setModal}
                                        showKeyboard={this.showKeyboard}
                                        reloadCart={this.reloadCart}
                                        cartReloaded={this.state.cartReloaded}
                                    />
                                } />
                                <Route exact path="/en-attente" render={() =>
                                    <Commandattente
                                        reloadCart={this.reloadCart}
                                        commandesEnAttente={this.state.commandesEnAttente}
                                        checkApiData={this.checkApiData}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/a-valider" render={() =>
                                    <Commandavalider
                                        reloadCart={this.reloadCart}
                                        commandesAvalider={this.state.commandesAvalider}
                                        checkApiData={this.checkApiData}
                                        showCommandEtat={this.showCommandEtat}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/en-cours" render={() =>
                                    <Commandencours
                                        commandesEnCours={this.state.commandesEnCours}
                                        reloadCart={this.reloadCart}
                                        checkApiData={this.checkApiData}
                                        showCommandEtat={this.showCommandEtat}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/prete" render={() =>
                                    <Commandprete
                                        commandesPrete={this.state.commandesPrete}
                                        reloadCart={this.reloadCart}
                                        checkApiData={this.checkApiData}
                                        showCommandEtat={this.showCommandEtat}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/en-livraison" render={() =>
                                    <Commandenlivraison
                                        commandesEnLivraison={this.state.commandesEnLivraison}
                                        reloadCart={this.reloadCart}
                                        checkApiData={this.checkApiData}
                                        showCommandEtat={this.showCommandEtat}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/terminees" render={() =>
                                    <Commandterminees
                                        commandesTerminees={this.state.commandesTerminees}
                                        reloadCart={this.reloadCart}
                                        checkApiData={this.checkApiData}
                                        showCommandEtat={this.showCommandEtat}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/annulees" render={() =>
                                    <Commandannulees
                                        commandesAnnulees={this.state.commandesAnnulees}
                                        reloadCart={this.reloadCart}
                                        checkApiData={this.checkApiData}
                                        showCommandEtat={this.showCommandEtat}
                                        emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/facture/:id" render={(props) =>
                                    <Facture
                                        id={props.match.params.id}
                                        auth_user={this.state.auth_user}
                                        showKeyboard={this.showKeyboard}
                                        reloadCart={this.reloadCart}
                                    // checkApiData={this.checkApiData}
                                    // showCommandEtat={this.showCommandEtat}
                                    // emptyCart={this.emptyCart}
                                    />
                                } />
                                <Route exact path="/search" render={() =>
                                    <Search
                                        showKeyboard={this.showKeyboard}
                                        auth_user={this.state.auth_user}
                                    />
                                } />
                                <Route exact path="/journal" render={() =>
                                    <Journal
                                        showKeyboard={this.showKeyboard}
                                        auth_user={this.state.auth_user}
                                        reloadCart={this.reloadCart}
                                    />
                                } />
                                <Route exact path="/messages" render={() =>
                                    <Messages
                                        showKeyboard={this.showKeyboard}
                                        auth_user={this.state.auth_user}
                                        commandesReclamations={this.state.commandesReclamations}
                                        ticketsMessages={this.state.ticketsMessages}
                                        contactMessages={this.state.contactMessages}
                                    />
                                } />
                                <Route exact path="/produit" render={() =>
                                    <NewProduct
                                        auth_user={this.state.auth_user}
                                        showKeyboard={this.showKeyboard}
                                        reloadCart={this.reloadCart}
                                    />
                                } />
                            </div>

                            {this.state.modal_show ? (
                                <div className={`pop-modal ${this.state.isCustomisationModal ? 'pop-customisation' : ''}`}>
                                    <div className="pop-details">
                                        <div className="pop-header"> <span className="closeBtn" onClick={() => this.setModal(0)}><img className="icon-arrow-right" src={require('./assets/images/x.svg')} alt="" /></span></div>
                                        <div className="clearfix pop-body">
                                            {this.state.modal_show}
                                        </div>
                                    </div>
                                </div>
                            ) : ""}

                        </div>
                    </div>
                </HashRouter>
            );
        } else {
            return (
                <HashRouter>
                    <div className="app container-fluid p-0">
                        <div id="sidebar">
                            {this.state.keyboard_show ? (
                                <Keyboard
                                    showKeyboard={this.showKeyboard}
                                    keyboardHandling={this.state.keyboardHandling}
                                    input={this.state.keyboard_input}
                                    ref={this.child}
                                />
                            ) : ""}
                        </div>
                        <div id="main">
                            <Login
                                authenticate={this.authenticate}
                                showKeyboard={this.showKeyboard}
                            />
                        </div>
                    </div>
                </HashRouter>
            );
        }
    }

    setLastProduct() {
        let lastProduct = this.state.cartItems.products ? Object.values(this.state.cartItems.products) : {};
        lastProduct = lastProduct[lastProduct.length - 1];
        this.setState({ lastProduct: lastProduct || {} });
    }

    // set modal
    setModal = (val = null, param1 = null, param2 = null) => {
        let component = null;
        let selectedProduct = param1;
        let isCustom = false;
        switch (val) {
            case 'livraison':
                let refer_liv = param1 ? param1 : 'home';
                component = <Livraison
                    setTypeLivraison={this.setTypeLivraison}
                    refer={refer_liv}
                    setModal={this.setModal}
                    showKeyboard={this.showKeyboard}
                    reloadCart={this.reloadCart}
                />;
                break;

            case 'confirmcommand':
                component = <Confirmcommand
                    setModal={this.setModal}
                    setTypeLivraison={this.setTypeLivraison}
                    checkApiData={this.checkApiData}
                    reloadCart={this.reloadCart}
                />;
                break;

            case 'client':
                let refer = param1 ? param1 : 'home';
                component = <Client
                    showKeyboard={this.showKeyboard}
                    setModal={this.setModal}
                    refer={refer}
                    reloadCart={this.reloadCart}
                />;
                break;

            case 'personnaliser':
                isCustom = true;
                component = <Personnaliser
                    product={param1}
                    data={param2}
                    reloadCart={this.reloadCart}
                    cartItems={this.state.cartItems}
                />;
                break;

            case 'reduction':
                component = <Reduction
                    selectedProduct={selectedProduct}
                    reloadCart={this.reloadCart}
                    showKeyboard={this.showKeyboard}
                />
                break;

            default:
                component = 0;
                break;
        }
        this.setState({
            modal_show: component,
            selectedProduct: selectedProduct,
            isCustomisationModal: isCustom
        });
    }

    // reload sidebar cart
    reloadCart = (product = null, operation = 'all', param = null) => {
        const auth_user = this.state.auth_user.user;
        let check_data_api = false;
        const cart = loadCart().get() || {};

        const command = {
            "livraison": cart.livraison,
            "reduction": cart.reduction,
            "frais": cart.frais,
            "client": cart.client,
            "livraison_delay": cart.livraison_delay,
            "remarque": cart.remarque,
            "mode_paiement": cart.mode_paiement,
            "command_attente_id": cart.command_attente_id,
            "command_id": cart.command_id,
            "tva": cart.tva,
            "ht": cart.ht,
            "total": cart.total,
            "total_reduction": cart.total_reduction,
            "tva_products": cart.tva_products,
        }
        let obj_url = { "product": product, "command": command };
        let url_params = encodeURIComponent(JSON.stringify(obj_url));

        if (operation === "order" || operation === "mettre-en-attente") {
            url_params = encodeURIComponent(JSON.stringify({
                "command": command,
                "products": cart.products
            }));
            check_data_api = true;
        }
        else if (operation === "get-command") {
            let cmd = { "command_id": param };
            url_params = encodeURIComponent(JSON.stringify(cmd));
        }
        else if (operation === "delete-command-en-attente") {
            let cmd = { "command_attente_id": param };
            url_params = encodeURIComponent(JSON.stringify(cmd));
            check_data_api = true;
        }
        else if (operation === "reduction") {
            url_params = encodeURIComponent(JSON.stringify({
                "product": product,
                "reduction": param
            }));
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ jdata: url_params })
        };
        fetch(api_url + `get-cart/${operation}?api_token=${auth_user.api_token}`, requestOptions)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else if (response === "empty") {
                    clearCart();
                    this.setState({
                        cartItems: {},
                        cartReloaded: 1
                    });
                } else if (response) {
                    loadCart().persist(response);
                    this.setState({
                        cartItems: (loadCart().get() || []),
                        cartReloaded: 1,
                    }, function () {
                        if (product && product.is_menu && operation === "add") {
                            this.personalizeProduct(product);
                        }
                        if (operation === 'delete') {
                            this.setModal(0);
                        }
                        /*if (operation === "order" && param === "print") {
                            window.print();
                        }*/
                    });
                }
                if (check_data_api === true) {
                    this.checkApiData();
                }
                if (response.notification) {
                    this.showNotification(response.notification);
                }

                this.setLastProduct();
            });
    }

    emptyCart = () => {
        const cart = this.state.cartItems;
        if (cart.command_id) {
            clearCart();
            this.setState({
                cartItems: {},
                cartReloaded: 1
            });
        }
    }

    // show keyboard
    showKeyboard = (val = 0, handle = null, input = null) => {
        this.setState({
            keyboard_show: val,
            keyboardHandling: handle,
            keyboard_input: input,
            cartReloaded: 0
        }, function () {
            if (handle && input) {
                this.child.current.inputChanging(input);
            }
        });
    }

    // show command etat sidebar
    showCommandEtat = (val = 0, cmd = null) => {
        this.setState({
            etat_command_show: val,
            command_selected: cmd
        });

        // this.toggleSidebar();
    }

    // Change etat command
    changeEtatCommand = (command) => {
        const user = this.state.auth_user.user;
        let cmd = { "command": command };
        let url_params = encodeURIComponent(JSON.stringify(cmd));
        fetch(api_url + `update-commands/${url_params}?api_token=` + user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else {
                    this.setState({
                        etat_command_show: 0,
                    }, function () { this.checkApiData(); });
                }
            });

        this.toggleSidebar();
    }

    // authenticate
    authenticate = () => {
        this.setState({
            auth_user: authUser().get()
        }, function () {
            this.checkApiData();
            this.emptyCart();
            this.reloadCart(null, "clear-cart");
        });
    }

    // Logout
    logout = () => {
        logout();
        clearCart();
        this.setState({ auth_user: null });
    }

    // Check api data
    checkApiData = () => {
        const auth_user = this.state.auth_user;
        if (auth_user) {
            const user = auth_user.user;
            fetch(api_url + `get-commands/all?api_token=` + user.api_token)
                .then(results => { return results.json(); })
                .then(response => {
                    if (response === "Unauthorized") {
                        this.logout();
                    } else {
                        this.setState({
                            commandesEnAttente: response.enAttente,
                            commandesAvalider: response.aValider,
                            commandesEnCours: response.enCours,
                            commandesPrete: response.prete,
                            commandesEnLivraison: response.enLivraison,
                            commandesTerminees: response.terminees,
                            commandesAnnulees: response.annulees,
                            commandesReclamations: response.reclamations,
                            ticketsMessages: response.tickets,
                            contactMessages: response.contactmessages,
                            cartReloaded: 0
                        });
                    }
                });
        }
    }

    // Change type livraison
    setTypeLivraison = (type, action = null) => {
        this.setState({ type_livraison: type }, function () {
            const cart = loadCart().get() || {};
            cart.livraison = type;
            loadCart().persist(cart);

            if (action === "clear-cart")
                this.reloadCart(null, "clear-cart");

            if (action === "reload-cart")
                this.reloadCart();
        });
    }

    // personnaliser un produit
    personalizeProduct = (product) => {
        if (!product.is_menu && !product.is_personalizable) return false;

        const auth_user = this.state.auth_user.user;
        const str_product = encodeURIComponent(JSON.stringify(product));

        fetch(api_url + `customisation/${str_product}?api_token=${auth_user.api_token}`)
            .then(results => { return results.json(); })
            .then(data => {
                if (this.child.current) {
                    if (typeof this.child.current.setActiveProduct === 'function') {
                        this.child.current.setActiveProduct(product, true);
                    }
                }
                this.setModal("personnaliser", product, data);
            });
    }

    redirectTo = () => {
        //this.props.history.push(`/payment`);
        //return <Redirect to='/dashboard' />
    }

}

export default App;
