import React, { Component } from 'react';
import './sidebar.css';

class Keyboad extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numbers: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "."],
            letters: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "é", "è", "à"],
            keys: [],
            active: "letters",
            text: "",
            change_input: false,
            toggle: true
        };
    }

    componentWillMount() {
        this.setState({
            keys: this.state.letters
        });
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    setActive = () => {
        const active = this.state.active;
        if (active === "numbers") {
            this.setState({
                keys: this.state.letters,
                active: "letters"
            });

        } else if (active === "letters") {
            this.setState({
                keys: this.state.numbers,
                active: "numbers"
            });
        }
    }

    showKeyboard = (val) => {
        if (typeof this.props.showKeyboard === 'function') {
            this.props.showKeyboard(val);
        }
    }

    makeText = (key) => {
        let text = this.state.text;
        this.setState({
            text: text += key,
        }, function () { this.keyboardHandling(); });
    }

    clearLastChar = () => {
        let text = this.state.text;
        if (text) {
            this.setState({ text: text.substring(0, text.length - 1) }, function () { this.keyboardHandling(); });
        }
    }

    inputChanging = (input) => {
        let toggle = true;
        let keys = this.state.letters;
        let active = "letters";
        if (input.type === "number") {
            toggle = false;
            keys = this.state.numbers;
            active = "numbers";
        }
        this.setState({
            text: "",
            change_input: true,
            toggle: toggle,
            keys: keys,
            active: active
        });
    }

    keyboardHandling = () => {
        let text = this.state.text;
        if (this.state.change_input) {
            let input_text = this.props.input.value;
            if (input_text) {
                let position = this.props.input.selectionStart;
                text = [input_text.slice(0, position), text, input_text.slice(position)].join('');
            }
        }
        this.setState({
            text: text,
            change_input: false
        }, function () {
            if (typeof this.props.keyboardHandling === 'function') {
                this.props.keyboardHandling(this.state.text);
            }
        });
    }

    clearText = () => {
        this.setState({ text: "" }, function () {
            if (typeof this.props.keyboardHandling === 'function') {
                this.props.keyboardHandling(this.state.text);
            }
        });
    }

    handleClickOutside = (e) => {
        if (this.node.contains(e.target)) {
            return;
        }
        this.showKeyboard(0);
    }

    render() {
        return (
            <React.Fragment>
                <div id="keyboad" ref={node => this.node = node}>
                    <div className="closeKeyboard"><button className="btn" onClick={() => this.showKeyboard(0)}><img className="icon-arrow-right" src={require('../../assets/images/x.svg')} alt="" /></button></div>
                    <div className="keyboad-keys">
                        <button className="btn btn-lg-4" onClick={() => this.clearText()}><span>Effacer</span></button>

                        <button className="btn" onClick={() => this.clearLastChar()}><img src={require('../../assets/images/left-arrow-white.svg')} alt="" height="25" /></button>

                        {this.state.keys.map(key =>
                            <button className="btn" key={key} onClick={() => this.makeText(key)}>{key}</button>
                        )}

                        {this.state.active === "letters" ? (
                            <button className="btn btn-lg-2" onClick={() => this.makeText(" ")}><span>Espace</span></button>
                        ) : ""}

                        {this.state.toggle ? (
                            <button className="btn btn-lg-4" onClick={() => this.setActive()}><span>{this.state.active === "numbers" ? "Lettres" : "Nombres"}</span></button>
                        ) : ""}
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default Keyboad;