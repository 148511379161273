import React, { Component } from 'react';
import './payment.css';
import { Link, withRouter } from "react-router-dom";
import { loadCart } from "../helpers/cartHelper";
import { config } from "../helpers/configHelper";

class Payment extends Component {

    constructor(props) {
        super(props);
        const cart = loadCart().get() || {};
        this.state = {
            cartItems: [],
            payment_methods: [],
            active_mode: null,
            total: 0.00,
            total_global: 0.00,
            price_entered: 0.00,
            reduction: (cart.reduction ? cart.reduction.value : ''),
            reduction_type: (cart.reduction ? cart.reduction.type : "%"),
            reduction_libelle: (cart.reduction ? cart.reduction.libelle : "Réduction"),
            frais: (cart.frais ? cart.frais.value : ''),
            frais_type: (cart.frais ? cart.frais.type : "%"),
            frais_libelle: (cart.frais ? cart.frais.libelle : "Autres frais"),
            active_modal: null,
        };
        this.child = React.createRef();
    }

    UNSAFE_componentWillMount() {
        this.reloadPrices();
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.cartReloaded) {
            this.reloadPrices();
        }
    }

    reloadPaymentMethods = () => {
        let arr = [];
        config.payment_methods.forEach((method) => {
            arr[method] = {
                libelle: method,
                price: "0.00"
            }
        });
        this.setState({
            payment_methods: arr,
            price_entered: "0.00",
            active_mode: null
        });
    }

    changePriceHandling = (event) => {
        let value = (typeof event.target !== 'undefined') ? event.target.value : event;
        let value_float = parseFloat(value);

        let payment_methods = this.state.payment_methods;
        payment_methods[this.state.active_mode].price = parseFloat(value_float).toFixed(2);

        this.setState({
            price_entered: value,
            payment_methods: payment_methods
        });
    }

    activeMode = (mode) => { return this.state.active_mode === mode ? ' disabled' : ''; }
    changeMode = (mode) => {
        let total = this.state.total;
        let payment_methods = this.state.payment_methods;
        let payment_methods_values = Object.values(payment_methods);

        payment_methods_values.forEach((method) => {
            if (method.libelle !== mode) {
                total -= method.price
            }
        });

        if (total > 0) {
            payment_methods[mode].price = parseFloat(total).toFixed(2);
        }

        this.setState({
            //total: total,
            active_mode: mode,
            price_entered: payment_methods[mode].price,
            payment_methods: payment_methods,
        }, function () { this.activateCaisse(); });
    }

    clearInput = () => {
        let active_mode = this.state.active_mode;
        let payment_methods = this.state.payment_methods;
        if (active_mode) payment_methods[this.state.active_mode].price = "0.00";
        this.setState({
            price_entered: "0.00",
            payment_methods: payment_methods,
            active_mode: null
        });
    }

    changeReductionHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ reduction: val }, function () { this.getTotal() });
    }

    changeCalculeMethod = (type) => {
        if (type === "frais") {
            this.setState({ frais_type: (this.state.frais_type === "%" ? "€" : "%") }, function () { this.getTotal() });
        } else if (type === "reduction") {
            this.setState({ reduction_type: (this.state.reduction_type === "%" ? "€" : "%") }, function () { this.getTotal() });
        }
    }

    changeFraisHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ frais: val }, function () { this.getTotal() });
    }

    reductionLibelleHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ reduction_libelle: val });
    }

    changeFraisLibelleHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ frais_libelle: val });
    }

    getTotal = () => {
        let total = this.state.total_global;

        // const reduction = parseFloat(this.state.reduction);
        // if ( reduction >= 0 ) {
        //     let reduc = this.state.reduction_type === "%" ? ((total*reduction)/100) : reduction;
        //     total -= reduc;
        // }

        // const frais = parseFloat(this.state.frais);
        // if ( frais >= 0 ) {
        //     let frai = this.state.frais_type === "%" ? ((total*frais)/100) : frais;
        //     total += frai;
        // }

        this.setState({ total: parseFloat(total).toFixed(2) }, function () { this.reloadPaymentMethods() });
    }

    reloadPrices = () => {
        const cartItems = (loadCart().get() || []);
        let arr = [];
        config.payment_methods.forEach((method) => {
            arr[method] = {
                libelle: method,
                price: "0.00"
            }
        });
        this.setState({
            total: cartItems.total || 0,
            total_global: cartItems.total || 0,
            payment_methods: arr
        }, function () {
            this.getTotal();
        });
    }

    activateCaisse = () => {
        let payment_methods = this.state.payment_methods;
        let payment_methods_values = Object.values(payment_methods);
        let price = 0;
        payment_methods_values.forEach((method) => {
            price += parseFloat(method.price);
        });

        price = parseFloat(price).toFixed(2);
        let ttc = this.state.total;
        return ((ttc !== price || price <= 0) ? "disabled" : "");
    }

    setModal = (modal) => {
        if (typeof this.props.setModal === 'function') {
            this.props.setModal(modal, "payment");
        }
    }

    showKeyboard = (event, handle = null) => {
        if (typeof this.props.showKeyboard === 'function') {
            this.props.showKeyboard(1, handle, event.target);
        }
    }

    applyReductionFrais = () => {
        let cart = loadCart().get() || {};
        if (this.state.reduction >= 0) {
            cart.reduction = {
                "libelle": this.state.reduction_libelle,
                "type": this.state.reduction_type,
                "value": this.state.reduction
            };
            // cart.frais = {
            //     "libelle": this.state.frais_libelle,
            //     "type": this.state.frais_type,
            //     "value": this.state.frais
            // };
            loadCart().persist(cart);

            if (typeof this.props.reloadCart === 'function') {
                this.props.reloadCart();
            }
        }
    }

    caisse = () => {
        let arr_pay = [];
        Object.values(this.state.payment_methods).forEach(mode => {
            if (mode.price > 0) {
                arr_pay.push(mode);
            }
        });

        let cart = loadCart().get();
        cart.mode_paiement = arr_pay;
        loadCart().persist(cart);

        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "order", "print");

            /* Vider Client */
            cart.client = {};
            loadCart().persist(cart);

            this.props.history.push(`/`);
        }
    }

    render() {
        return (
            <React.Fragment>

                <div id="payment">
                    <div className="payment-inner">
                        <div className="price-total mb-4 w4btns">{parseFloat(this.state.total).toFixed(2)}€</div>

                        <div className="cols mb-5">
                            <div className="w4btns mb-2">
                                <div className="payment-input-price">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.reduction_libelle}
                                            onChange={this.reductionLibelleHandling}
                                            onClick={(event) => this.showKeyboard(event, this.reductionLibelleHandling)}
                                        />
                                        <input
                                            type="number"
                                            className="form-control input-value"
                                            value={this.state.reduction}
                                            onChange={this.changeReductionHandling}
                                            onClick={(event) => this.showKeyboard(event, this.changeReductionHandling)}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn" type="button" onClick={() => this.changeCalculeMethod('reduction')}>-{this.state.reduction + this.state.reduction_type}</button>
                                            <button className="btn bg-dark sm-btn" type="button" onClick={() => this.applyReductionFrais()}>
                                                <img className="icon-arrow-right" src={require('../../assets/images/minus.svg')} alt="" />
                                                <small>Valider</small>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w4btns">
                                <div className="payment-input-price">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control w50p"
                                            value={this.state.frais_libelle}
                                            onChange={this.changeFraisLibelleHandling}
                                            onClick={(event) => this.showKeyboard(event, this.changeFraisLibelleHandling)}
                                        />
                                        <input
                                            type="number"
                                            className="form-control input-value"
                                            value={this.state.frais}
                                            onChange={this.changeFraisHandling}
                                            onClick={(event) => this.showKeyboard(event, this.changeFraisHandling)}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn" type="button" onClick={() => this.changeCalculeMethod("frais")}>+{this.state.frais + this.state.frais_type}</button>
                                            <button className="btn bg-dark sm-btn" type="button" onClick={() => this.applyReductionFrais()}>
                                                <img className="icon-arrow-right" src={require('../../assets/images/plus.svg')} alt="" />
                                                <small>Valider</small>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="list-btns payment-methods text-center mb-4">
                            {Object.values(this.state.payment_methods).map((method, index) =>
                                <div className={`btn-app mb-0${this.activeMode(method.libelle)}`} key={Math.random()} onClick={() => this.changeMode(method.libelle)}>
                                    <div className="img-container">
                                        <img className="icon" src={require(`../../assets/images/pay-${index + 1}.svg`)} alt="" />
                                        <span className="title">{method.libelle}</span>
                                    </div>
                                    <h4 className="btn-title item-price">{method.price}€</h4>
                                </div>
                            )}
                        </div>

                        <div className="payment-input-price w4btns mb-5">
                            <div className="input-group">
                                {/* this.state.active_mode ? (
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        value={this.state.price_entered} 
                                        onChange={this.changePriceHandling} 
                                        onClick={(event) => this.showKeyboard(event, this.changePriceHandling)} 
                                    />
                                ) : (
                                    <span className="form-control">{this.state.price_entered}</span>
                                ) */}

                                <input
                                    disabled={!this.state.active_mode}
                                    type="text"
                                    className="form-control"
                                    value={this.state.price_entered}
                                    onChange={this.changePriceHandling}
                                    onClick={(event) => this.showKeyboard(event, this.changePriceHandling)}
                                />

                                <div className="input-group-append">
                                    <button className="btn bg-dark btn-reset" type="button" onClick={() => this.clearInput()}>
                                        <img className="icon-arrow-right" style={{ height: '16px' }} src={require('../../assets/images/x.svg')} alt="" />
                                        <small>Initaliser</small>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="list-btns text-center">
                            <div className={`btn-app`} onClick={() => this.setModal('client')}>
                                <div className="img-container h-100p">
                                    <img className="icon" src={require("../../assets/images/avatar.svg")} alt="" />
                                    <span className="title">Informations client</span>
                                </div>

                            </div>
                            <div className={`btn-app`} onClick={() => this.setModal('livraison')}>
                                <div className="img-container h-100p">
                                    <img className="icon" src={require("../../assets/images/livraison-a-domicile.svg")} alt="" />
                                    <span className="title">Livraison</span>
                                </div>
                            </div>
                            <Link to="/categories" className={`btn-app`}>
                                <div className="img-container h-100p">
                                    <img className="icon" src={require("../../assets/images/a-emporter.svg")} alt="" />
                                    <span className="title">Reprendre</span>
                                </div>
                            </Link>
                            <div className={`btn-app ${this.activateCaisse()}`} onClick={() => this.caisse()}>
                                <div className="img-container h-100p">
                                    <img className="icon" src={require("../../assets/images/bill.svg")} alt="" />
                                    <span className="title">Caisse</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(Payment);
