import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { config } from '../helpers/configHelper';
import { api_url } from '../helpers/cartHelper';

class NewProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: config.product_fields,
            active_field: null,
            operation: 'add',
            auth_user: props.auth_user.user,
            responseMessage: props.responseMessage,
            search_value: '',
            products: [],
            productRow: null,
        };
    }

    handleChange = (event, inp = null) => {
        const field = inp ? inp : this.state.active_field;

        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        const fields = this.state.fields;

        let index = fields.findIndex(item => item.name === field);
        let row = fields[index];
        row.value = val;

        this.setState({ fields: fields });

    }

    showKeyboard = (event, handle = null) => {
        let target = event.target;
        this.setState({ active_field: target.name }, function () {
            if (typeof this.props.showKeyboard === 'function') {
                this.props.showKeyboard(1, handle, target);
            }
        });
    }

    saveProduct = () => {
        const fields = this.state.fields;
        let isValid = true;

        let data = {};
        fields.forEach(field => {
            field.classError = null;
            if (field.required && !field.value) {
                field.classError = "is-invalid";
                isValid = false;
            }
            if (field.value) {
                data[field.name] = field.value;
            }
        });

        this.setState({ fields: fields });

        if (isValid) {
            const auth_user = this.state.auth_user;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
            fetch(api_url + `products/create?api_token=${auth_user.api_token}`, requestOptions)
                .then(results => { return results.json(); })
                .then(response => {
                    if (response.message) {
                        alert(response.message);
                    } else if (response.success) {
                        if (typeof this.props.reloadCart === 'function') {
                            this.props.reloadCart(data, 'new-product');

                            fields.forEach(field => { field.value = null; });
                            this.setState({ fields: fields });

                            this.props.history.push(`/categories`);
                        }

                    }
                });
        }
    }

    getActiveOperation = (op) => { return this.state.operation === op ? " active" : ""; }
    setActiveOperation = (op) => {
        let fields = this.state.fields;
        fields.forEach(field => { field.value = null; });

        let new_op = op === this.state.operation ? "" : op;
        this.setState({
            operation: new_op,
            productRow: null,
            fields: fields
        });
    }

    changeSearchHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ search_value: val }, function () {
            if (val) {
                const auth_user = this.state.auth_user;
                fetch(api_url + `products/search/${val}?api_token=${auth_user.api_token}`)
                    .then(results => { return results.json(); })
                    .then(data => {
                        this.setState({
                            products: data.products,
                        }, function () {
                            if (this.state.products.length === 1) {
                                let prd = this.state.products[0];
                                if (prd.code === val) {
                                    this.selectProduct(prd);
                                }
                            }
                        });
                    });
            } else {
                this.setState({ products: [] });
            }
        });
    }

    selectProduct = (product) => {
        let fields = this.state.fields;
        fields.map(function (field) {
            if (field.name === 'title') {
                field.value = product.name;
            } else if (field.name === 'price_ht') {
                field.value = product.price;
            } else if (field.name === 'tva') {
                field.value = product.tva;
            } else if (field.name === 'code') {
                field.value = product.code;
            } else if (field.name === 'reference') {
                field.value = product.reference;
            } else if (field.name === 'description') {
                field.value = product.description;
            } else if (field.name === 'stock') {
                field.value = product.stock;
            }
        });
        this.setState({
            productRow: product,
            fields: fields,
        });
    }

    updateProduct = (product) => {

        const fields = this.state.fields;

        let data = {};
        fields.forEach(field => {
            data[field.name] = field.value;
        });

        const auth_user = this.state.auth_user;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch(api_url + `products/update/${product.id}?api_token=${auth_user.api_token}`, requestOptions)
            .then(results => { return results.json(); })
            .then(data => {
                if (data.message) {
                    alert(data.message);
                } else if (data.success) {
                    alert('Modification a été effectuée avec succès');
                    this.setState({
                        productRow: null,
                        search_value: '',
                        products: [],
                    })
                }
            });
    }

    render() {
        const fields = this.state.fields;
        return (
            <React.Fragment>

                <div className='clearfix'>
                    <div className="btn-group btn-group-etat">
                        <button type="button" className={`btn btn-light${this.getActiveOperation('add')}`} onClick={() => this.setActiveOperation('add')}>Ajouter un produit</button>
                        <button type="button" className={`btn btn-light${this.getActiveOperation('edit')}`} onClick={() => this.setActiveOperation('edit')}>Modifier un produit</button>
                    </div>

                </div>

                <br />

                {this.state.operation === 'add' ? (
                    <div className='row'>
                        <div className='col-md-12'>
                            <h4 className="home-title">- Ajouter un produit</h4>
                            <hr />
                        </div>
                        {fields.map(field =>
                            <div className={`form-group ${field.class ? field.class : 'col-sm-12'}`} key={field.label}>
                                <label>{field.label} <small className="text-danger">{field.required ? "*" : ""}</small></label>
                                {field.type === "textarea" ? (
                                    <textarea
                                        className={`form-control ${field.classError}`}
                                        name={field.name}
                                        required={field.required}
                                        rows="3"
                                        value={field.value}
                                        onChange={(event) => this.handleChange(event, field.name)}
                                        onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                    ></textarea>
                                ) : (
                                    <input
                                        type={field.type}
                                        value={field.value}
                                        className={`form-control ${field.classError}`}
                                        name={field.name}
                                        required={field.required}
                                        onChange={(event) => this.handleChange(event, field.name)}
                                        onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                    />
                                )}
                            </div>
                        )}
                        <div className="col-md-12">
                            <button type="button" className="btn btn-payment text-center" onClick={() => this.saveProduct()}>
                                Ajouter
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className='clearfix'>

                        <div className='row'>
                            <div className='col-md-12'>
                                <h4 className="home-title">- Modifier un produit</h4>
                                {!this.state.productRow ? (
                                    <div className='mb-4'>
                                        <hr />
                                        <input
                                            type="text"
                                            className="form-control border"
                                            value={this.state.search_value}
                                            placeholder="Recherche..."
                                            onChange={this.changeSearchHandling}
                                            autoFocus
                                        />
                                    </div>

                                ) : ''}
                                {(this.state.products.length && !this.state.productRow) ? (
                                    <table className='table'>
                                        <tbody>
                                            {this.state.products.map(product =>
                                                <tr key={product.id}>
                                                    <td>
                                                        <button type='button' className='btn btn-link' onClick={() => this.selectProduct(product)}>{product.name}</button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                ) : ''}
                                <hr />
                            </div>
                        </div>

                        {this.state.productRow ? (
                            <div className='row'>
                                {fields.map(field =>
                                    <div className={`form-group ${field.class ? field.class : 'col-sm-12'}`} key={field.label}>
                                        <label>{field.label} <small className="text-danger">{field.required ? "*" : ""}</small></label>
                                        {field.type === "textarea" ? (
                                            <textarea
                                                className={`form-control ${field.classError}`}
                                                name={field.name}
                                                required={field.required}
                                                rows="3"
                                                value={field.value}
                                                onChange={(event) => this.handleChange(event, field.name)}
                                                onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                            ></textarea>
                                        ) : (
                                            <input
                                                type={field.type}
                                                value={field.value}
                                                className={`form-control ${field.classError}`}
                                                name={field.name}
                                                required={field.required}
                                                onChange={(event) => this.handleChange(event, field.name)}
                                                onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                            />
                                        )}
                                    </div>
                                )}
                                <div className="col-md-12">
                                    {/* <button type="button" className="btn btn-payment text-center" onClick={() => this.updateProduct()}>
                                        Annuler
                                    </button> */}
                                    <button type="button" className="btn btn-payment text-center" onClick={() => this.updateProduct(this.state.productRow)}>
                                        Modifier
                                    </button>
                                </div>
                            </div>
                        ) : ''}

                    </div>
                )}



            </React.Fragment>
        );
    }
}

export default withRouter(NewProduct);
