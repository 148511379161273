import React, { Component } from 'react';
import './home.css';
import Livraison from './Livraison';
import { Link } from "react-router-dom";
import Client from './Client';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user.user
        }
    }

    componentWillMount() {
        if (typeof this.props.setModal === 'function') {
            this.props.setModal(0);
        }
        if (typeof this.props.emptyCart === "function") {
            this.props.emptyCart();
        }
    }

    render() {
        const auth_user = this.state.auth_user;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="home-title">- Nouvelle commande</h4>
                        <Livraison
                            setModal={this.props.setModal}
                            setTypeLivraison={this.props.setTypeLivraison}
                            reloadCart={this.props.reloadCart}
                            checkApiData={this.props.checkApiData}
                        />
                    </div>
                    <div className="col-md-6">
                        <h4 className="home-title">- Autres</h4>
                        <div className="list-btns text-left clearfix">
                            {auth_user.role === 'admin' ? (
                                <Link to="/journal" className="btn-app bg-yellow">
                                    <div className="img-container">
                                        <img className="icon" src={require("../../assets/images/bar-chart.svg")} alt="Statistiques" />
                                    </div>
                                    <h4 className="btn-title">Journal</h4>
                                </Link>
                            ) : ''}
                            <div className="btn-app bg-red" onClick={this.props.logout}>
                                <div className="img-container"><img className="icon" src={require("../../assets/images/logout.svg")} alt="logout" /></div>
                                <h4 className="btn-title">Se déconnecter</h4>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <hr />

                <Client
                    autosave={true}
                    reloadCart={this.props.reloadCart}
                />

            </React.Fragment>
        );
    }
}

export default Home;
