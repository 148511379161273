import React, { Component } from 'react';

class Reclamations extends Component 
{
    render() {
        const reclamations = this.props.reclamations;
        return (
            <fieldset className="pop-fieldset">
                <legend>Réclamations</legend>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Commande N°</th>
                            <th>Client</th>
                            <th>Date</th>
                            <th>Message</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reclamations.map(reclam => 
                            <tr key={Math.random()} className={reclam.view===this.props.view?"":"d-none"}>
                                <td>{ reclam.n_order }</td>
                                <td>{ reclam.client }</td>
                                <td>{ reclam.date }</td>
                                <td>{ reclam.message }</td>
                                <td>
                                    <button type="button" className="btn btn-primary form-control bg-dark" onClick={()=>this.props.showMessage('reclamation', reclam)}>Voir</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </fieldset>
        );
    }
}

export default Reclamations;
