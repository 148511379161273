import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { loadCart } from '../helpers/cartHelper';

class Commandattente extends Component {

    componentWillMount() {
        if (typeof this.props.emptyCart === "function") {
            this.props.emptyCart();
        }
        if (typeof this.props.emptyCart === "function") {
            this.props.emptyCart();
        }
    }

    loadCommand = (cmd) => {
        const cart = loadCart().get() || {};
        cart.command_attente_id = cmd.id;
        loadCart().persist(cart);
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "get-command-en-attente");
        }
        this.props.history.push(`/categories`);
    }

    deleteCommand = (cmd) => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "delete-command-en-attente", cmd.id);
        }
    }

    render() {
        const commands = this.props.commandesEnAttente || [];

        return (
            <React.Fragment>
                <h4 className="home-title">- Commandes en attentes</h4>
                <div id="list-comm" className="list-btns">
                    {commands.map((command) =>
                        <div className="btn-app-1" key={command.id}>
                            <div className={`btn-app hx2`} onClick={() => this.loadCommand(command)}>
                                <div className="img-container h-100p">Commande N°{command.id}</div>
                            </div>
                            <button className="btn btn-info btn-block" onClick={() => this.deleteCommand(command)}>Supprimer</button>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Commandattente);
