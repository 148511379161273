import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { api_url, paginate, apiListing } from "../helpers/cartHelper";

class category extends Component {

    constructor(props) {
        super(props);
        this.state = {
            category: [],
            category_id: this.props.id,
            products: [],
            sub_categories: [],
            products_paginate: [],
            variant: "",
            next_button: false,
            prev_button: false,
            auth_user: this.props.auth_user.user,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillReceiveProps(props) {
        this.fetchData(props);
    }

    fetchData(props) {
        const auth_user = this.state.auth_user;
        const category_id = (props || this.props).id;

        let listing = apiListing().get() || {};
        const cat_products = listing[`list_products_${category_id}`];

        if (!cat_products) {
            fetch(api_url + `products/${category_id}?api_token=${auth_user.api_token}`)
                .then(results => { return results.json(); })
                .then(data => {
                    //const paginate_func = paginate(data.products);
                    this.setState({
                        category: data.category,
                        products: data.products,
                        sub_categories: data.sub_categories,
                        products_paginate: data.products,//paginate_func.data,
                        //next_button: paginate_func.next_button,
                        //prev_button: paginate_func.prev_button,
                        variant: !this.state.variant ? (data.category && data.category.has_variants ? data.category.variants[0].title : "") : this.state.variant
                    }, function () {
                        listing[`list_products_${category_id}`] = data;
                        apiListing().persist(listing);
                    });
                });
        } else {
            const data = cat_products;
            //const paginate_func = paginate(data.products);
            this.setState({
                category: data.category,
                products: data.products,
                sub_categories: data.sub_categories,
                products_paginate: data.products,//paginate_func.data,
                //next_button: paginate_func.next_button,
                //prev_button: paginate_func.prev_button,
                variant: !this.state.variant ? (data.category.has_variants ? data.category.variants[0].title : "") : this.state.variant
            });
        }
    }

    paginateProducts = (products, nav) => {
        const paginate_func = paginate(this.state.products, products, nav);
        this.setState({
            products_paginate: paginate_func.data,
            next_button: paginate_func.next_button,
            prev_button: paginate_func.prev_button,
        });
    }

    addProduct = (product) => {
        product.uid = Math.floor(Math.random() * (+new Date()) * parseFloat(this.state.auth_user.id));
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(product, 'add');
        }
    }

    changeType = (type) => {
        if (this.state.variant === type) {
            this.setState({ variant: "" });
        } else {
            this.setState({ variant: type });
        }
    }

    activeType = (type) => {
        return this.state.variant === type ? ' active' : '';
    }

    showProduct = (product) => {
        if (this.state.category.has_variants)
            return ((product.variant === this.state.variant && product.variant_id) ? "" : " d-none");
        return "";
    }

    render() {
        const products = this.state.products_paginate;
        const category = this.state.category;
        //const sub_categories = this.state.sub_categories;

        return (
            <React.Fragment>
                <div className={`list-btns clearfix ${category.has_variants ? 'mb-4' : ''} list-btns-filters`}>
                    <div className="float-left">
                        <Link to="/categories" className="btn-app btn-app-auto">
                            <div className="img-container">
                                <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="" />
                            </div>
                        </Link>
                        <div className="btn-app btn-app-auto btn-bordered p-0 active">
                            <div className="btn-item">
                                <h4 className="btn-title">{category.name}</h4>
                            </div>
                        </div>
                    </div>

                    {category.has_variants ? (
                        <div className="float-left w50p btn-app-items">
                            {category.variants.map(variant =>
                                <div className={`btn-app btn-app-auto btn-bordered${this.activeType(variant.title)}`} onClick={() => this.changeType(variant.title)} key={Math.random()}>
                                    <div className="btn-item">
                                        <h4 className="btn-title">{variant.title}</h4>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : ""}

                </div>

                <div className="list-btns cat-products clearfix">

                    {(this.state.prev_button) ? (
                        <div className="btn-app btn-app-auto">
                            <div className="img-container" onClick={() => this.paginateProducts(products, 'prev')}>
                                <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="Prev" />
                            </div>
                        </div>
                    ) : ""}

                    {/* Sub Categories */}


                    {products.map(product =>
                        <div className={`btn-app${this.showProduct(product)}`} key={Math.random()} onClick={() => this.addProduct(product)}>
                            <div className="img-container">{product.name}</div>
                            <h4 className="btn-title item-price">{product.price}€</h4>
                        </div>
                    )}

                    {(this.state.next_button) ? (
                        <div className="btn-app btn-app-auto">
                            <div className="img-container" onClick={() => this.paginateProducts(products, 'next')}>
                                <img className="icon icon-next" src={require("../../assets/images/left-arrow.svg")} alt="Next" />
                            </div>
                        </div>
                    ) : ""}

                </div>

            </React.Fragment>
        );
    }
}

export default category;
