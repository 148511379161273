import React, { Component } from 'react';
import './sidebar.css';
import { loadCart } from "../helpers/cartHelper";
import PropTypes from "prop-types";

class Sidebar extends Component {

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context) {
        super(props, context);
        this.state = {
            active_product: props.active_product,
            auth_user: this.props.auth_user,
            btn_scroll_top: 0,
            btn_scroll_bottom: 0
        };
        this.child = React.createRef();
    }

    componentDidMount() {
        let lastProdButton = document.querySelector(".last-prod-actions-toggle");
        if (lastProdButton) {
            lastProdButton.addEventListener("click", () => {
                let actions = document.querySelector("#sidebarr .last-prod-actions");
                if (actions.classList.contains("is-visible"))
                    actions.classList.remove("is-visible");
                else
                    actions.classList.add("is-visible");
            })
        }

        let actionsList = document.querySelector("#sidebarr .last-prod-actions ul");
        if (actionsList) {
            actionsList.addEventListener("click", (e) => {
                if (!e.target.closest('.no-close'))
                    lastProdButton && lastProdButton.click();
            })
        }
    }

    componentWillReceiveProps(props) {
        //const documentHeight = (parseFloat(document.documentElement.offsetHeight) - 70); // document height - button height
        const cartItemsHeight = document.getElementById("cartItems").scrollHeight;
        const cartHeight = document.getElementById("cartItems").clientHeight;
        if (cartItemsHeight > cartHeight) {
            this.setState({
                btn_scroll_bottom: 1,
                btn_scroll_top: 1,
            });
        } else {
            this.setState({
                btn_scroll_bottom: 0,
                btn_scroll_top: 0,
            });
        }
    }

    reloadCart = () => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart();
        }
    }

    updateProduct = (product, operation, indice) => {
        product.indice_cart = indice;

        let action = operation;
        if (operation === "plus") {
            product.qte += 1;
            action = "changeqte";
        } else if (operation === "minus") {
            product.qte -= 1;
            action = "changeqte";
        }

        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(product, action);
        }
    }

    setActiveProduct = (product, open = false) => {
        const cart = loadCart().get() || {};
        if (cart.command_id) return false;
        this.setState({
            active_product: product.uid,
            //selectedProduct: null,
            //compositions: [],
            ingredients: [],
            menus: [],
            //show_reduction: 0,
        }, function () {
            if (open === false) {
                if (typeof this.props.setModal === 'function') {
                    this.props.setModal(0, product);
                }
            }
        });
    }

    closeProduct = () => {
        this.setState({
            active_product: null,
            //selectedProduct: null,
            //show_reduction: 0
        }, function () {
            if (typeof this.props.setModal === 'function') {
                this.props.setModal(0);
            }
        });
    }

    personalizeProduct = (product, indice) => {
        product.indice_cart = indice;
        if (typeof this.props.personalizeProduct === 'function') {
            this.props.personalizeProduct(product);
        }
    }

    reductionProduct = (product) => {
        this.setState({
            active_product: product.uid,
        }, function () {
            if (typeof this.props.setModal === 'function') {
                this.props.setModal("reduction", product);
            }
        });
    }

    printTicket = () => {
        var divToPrint = document.getElementById("tableTicketOrder");
        let text = divToPrint.outerHTML;
        var entities = [
            ['amp', '&'],
            ['apos', '\''],
            ['#x27', '\''],
            ['#x2F', '/'],
            ['#39', '\''],
            ['#47', '/'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '"']
        ];
        for (var i = 0, max = entities.length; i < max; ++i)
            text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

        let newWin = window.open("");
        newWin.document.write(text);
        newWin.print();
        newWin.close();
    }

    validateCommand = () => {
        this.props.toggleSidebar(false);

        const products = this.props.cartItems.products;
        let valid = true; let notif = "";
        Object.values(products).forEach(prd => {
            if (prd.is_menu && !prd.menus.length) {
                valid = false;
                notif = "Veuillez choisir toutes les compositions du menu: " + prd.name;
            }
        });
        if (valid === true) {
            this.context.router.history.push("/payment");
        } else {
            if (typeof this.props.showNotification === 'function') {
                this.props.showNotification(notif, "error");
            }
        }
    }

    scrollCart = (align) => {
        const cartItems = document.getElementById("cartItems");
        if (align === "bottom") cartItems.scrollTop += 50;
        else if (align === "top") cartItems.scrollTop -= 50;
    }

    render() {

        const cartItems = this.props.cartItems;
        const products = cartItems.products ? Object.values(cartItems.products) : [];

        let total = cartItems.total ? cartItems.total : '0';
        total = parseFloat(total).toFixed(2);

        const client = cartItems.client;

        const has_products = products.length;

        /*const restaurant = this.state.auth_user ? this.state.auth_user.restaurant : {};*/

        let lastProductIndex = this.props.cartItems.products ? Object.values(this.props.cartItems.products).findIndex(p => p.id === (this.props.lastProduct || {}).id) : -1;
        return (
            <React.Fragment>
                <aside id="sidebarr">
                    <div id="cart">
                        <div className="total">
                            {this.state.btn_scroll_top ? (<div className="arrow-top" onClick={() => this.scrollCart("top")}><img src={require('../../assets/images/arrow-left-white.svg')} alt="" /></div>) : ""}

                            {total}€

                            {this.state.btn_scroll_bottom ? (<div className="arrow-bottom" onClick={() => this.scrollCart("bottom")}><img src={require('../../assets/images/arrow-left-white.svg')} alt="" /></div>) : ""}
                        </div>

                        <div className="text-center logo-restaurant d-none">
                            { /*restaurant ? (<img src={restaurant.logo} alt="" height="80px" />) : ""*/}
                            VITA'SUN SAS
                        </div>

                        <div className="stars d-none">*******************************************</div>

                        <div className="cart-items-container">
                            <div className="cart-items" id="cartItems">
                                {products.map((product, indice) =>

                                    <div className="cart-item" key={Math.random()}>
                                        <div className="separateur"></div>
                                        <div className="cart-content" onClick={() => this.setActiveProduct(product)}>

                                            <h4 className="cart-title">
                                                <span className="white qte">{product.qte} x</span>
                                                <span className="title">{product.name} {product.variant ? " - " + product.variant : ""}</span>
                                                <span className="price">{product.price}€</span>
                                            </h4>
                                            {product.infos ? (
                                                <ul className="sub-items list-unstyled small"><li>{product.infos}</li></ul>
                                            ) : ''}

                                            {/* {product.price_ht_reduction > 0 ? (
                                                <ul className="sub-items list-unstyled">
                                                    <li>
                                                        &#8627; HT avec remise
                                                        <span className="price">{product.price_ht_reduction}€</span>
                                                    </li>
                                                </ul>
                                            ) : ""} */}

                                            {/* {product.tva_total ? (
                                                <ul className="sub-items list-unstyled">
                                                    <li>
                                                        &#8627; TVA ({product.tva_percent}%)
                                                        <span className="price">{product.tva_total}€</span>
                                                    </li>
                                                </ul>
                                            ) : ""} */}

                                            {/* {product.price_ttc ? (
                                                <ul className="sub-items list-unstyled">
                                                    <li>
                                                        &#8627; TTC
                                                        <span className="price">{product.price_ttc}€</span>
                                                    </li>
                                                </ul>
                                            ) : ""} */}

                                            {(product.reduction && product.reduction.value) ? (
                                                <ul className="sub-items list-unstyled">
                                                    <li>
                                                        &#8627; {product.reduction.libelle} ({product.reduction.value + product.reduction.type})
                                                        <span className="price">-{product.reduction.result}€</span>
                                                    </li>
                                                </ul>
                                            ) : ""}

                                            {(product.menus.length) ? (
                                                <ul className="sub-items list-inline">
                                                    {product.menus.map((menu) =>
                                                        <li className="list-inline-item" key={Math.random()}>{menu.name}</li>
                                                    )}
                                                </ul>
                                            ) : ""}

                                            {product.composition.length ? (
                                                <ul className="sub-items list-inline">
                                                    {product.composition.map((comp) =>
                                                        <li className="list-inline-item" key={Math.random()}>Sans {comp}</li>
                                                    )}
                                                </ul>
                                            ) : ""}

                                            {product.ingredients.length ? (
                                                <ul className="sub-items list-inline">
                                                    {product.ingredients.map((ingredient) =>
                                                        <li className="list-inline-item" key={Math.random()}>{ingredient.title}</li>
                                                    )}
                                                </ul>
                                            ) : ""}

                                            {product.tva_details ? (
                                                <ul className="sub-items list-unstyled d-none">
                                                    {product.tva_details.map(det =>
                                                        <li key={Math.random()}>- {det.product} {det.price}€ dont {det.tva_percent}% de tva</li>
                                                    )}
                                                </ul>
                                            ) : ""}

                                        </div>
                                        <div className={`actions${this.state.active_product === (product.uid) ? ' active' : ''}`}>
                                            <ul className="list-inline">
                                                <li className="list-inline-item" onClick={() => this.updateProduct(product, 'minus', indice)}>
                                                    <div className="action-link"><img src={require("../../assets/images/minus-symbol.svg")} alt="minusSymbol" /></div>
                                                </li>
                                                <li className="list-inline-item" onClick={() => this.updateProduct(product, 'plus', indice)}>
                                                    <div className="action-link"><img src={require("../../assets/images/plus-symbol.svg")} alt="plusSymbol" /></div>
                                                </li>
                                                <li className="list-inline-item" onClick={() => this.personalizeProduct(product, indice)}>
                                                    <div className="action-link">{product.is_menu ? "M" : product.is_personalizable ? "P" : ""}</div>
                                                </li>
                                                <li className="list-inline-item" onClick={() => this.reductionProduct(product)}>
                                                    <div className="action-link"><img src={require("../../assets/images/percentage.svg")} alt="percentageIcon" /></div>
                                                </li>
                                                <li className="list-inline-item" onClick={() => this.updateProduct(product, 'delete', indice)}>
                                                    <div className="action-link"><img src={require("../../assets/images/delete.svg")} alt="deleteIcon" /></div>
                                                </li>
                                                <li className="list-inline-item delete" onClick={() => this.closeProduct()}>
                                                    <div className="action-link"><img src={require("../../assets/images/plus-symbol.svg")} alt="plusSymbol" /></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                <div className="stars d-none">******************************************</div>

                                {cartItems.reduction_auto ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">{cartItems.reduction_auto.libelle} ({cartItems.reduction_auto.value + cartItems.reduction_auto.type})</span> <span className="price">-{cartItems.reduction_auto.result}€</span></h4>
                                                {cartItems.reduction_auto.description ? (
                                                    <ul className="sub-items list-unstyled small">
                                                        <li>{cartItems.reduction_auto.description}</li>
                                                    </ul>
                                                ) : ""}
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {cartItems.reduction ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        {/* <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Total avant réduction</span> <span className="price">{parseFloat(parseFloat(cartItems.reduction.result) + parseFloat(total)).toFixed(2)}€</span></h4>
                                            </div>
                                        </div> */}
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">{cartItems.reduction.libelle} ({cartItems.reduction.value + cartItems.reduction.type})</span> <span className="price">-{cartItems.reduction.result}€</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {cartItems.frais ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">{cartItems.frais.libelle} ({cartItems.frais.value + cartItems.frais.type})</span> <span className="price">+{cartItems.frais.result}€</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.total_reduction && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Total réduction</span> <span className="price">{cartItems.total_reduction}€</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.ht && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Total HT</span> <span className="price">{cartItems.ht}€</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.tva && has_products) ? (
                                    <div className="clearfix showprintt">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Total TVA</span> <span className="price">{cartItems.tva}€</span></h4>
                                                {/* {cartItems.tva_products ? (
                                                    <ul className="sub-items list-unstyled small">
                                                        {Object.keys(cartItems.tva_products).map((perc) =>
                                                            <li key={Math.random()}>
                                                                Total TVA ({perc}%)
                                                                <span className="price">{parseFloat(cartItems.tva_products[perc]).toFixed(2)}</span>
                                                            </li>
                                                        )}
                                                    </ul>
                                                ) : ""} */}
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(total && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Total TTC</span> <span className="price">{total}€</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.refund && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Prix TTC à rembourser</span> <span className="price">{cartItems.refund}€</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.command_id && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Ticket N°</span> <span className="price">{cartItems.command_id}</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.parent_id && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title"><span className="title">Ticket parent</span> <span className="price">{cartItems.parent_id}</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.livraison && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title">{cartItems.livraison.title}</h4>
                                                {(cartItems.livraison_delay && cartItems.livraison_delay.libelle) ? (
                                                    <ul className="sub-items list-unstyled small">
                                                        <li>Délai de livraison <span className="price">{cartItems.livraison_delay.libelle ? cartItems.livraison_delay.libelle : cartItems.livraison_delay.value}</span></li>
                                                    </ul>
                                                ) : ""}
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.mode_paiement && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title">Méthodes Paiement</h4>
                                                <ul className="sub-items list-unstyled small">
                                                    {cartItems.mode_paiement.map(p =>
                                                        <li key={p.libelle}>{p.libelle} <span className="price">{p.price}€</span></li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(client && has_products) ? (
                                    <div className="clearfix showprint">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title">Informations client</h4>
                                                <ul className="sub-items list-unstyled small">
                                                    <li>Nom <span className="price">{client.nom}</span></li>
                                                    {client.num ? (<li>Numéro client  <span className="price">{client.num}</span></li>) : ""}
                                                    {client.tva ? (<li>Numéro TVA  <span className="price">{client.tva}</span></li>) : ""}
                                                    {client.telephone1 ? (<li>Téléphone 1 <span className="price">{client.telephone1}</span></li>) : ""}
                                                    {client.telephone2 ? (<li>Téléphone 2 <span className="price">{client.telephone2}</span></li>) : ""}
                                                    {client.email ? (<li>Adresse mail <span className="price">{client.email}</span></li>) : ""}
                                                    {client.adresse ? (<li>Adresse <span className="price">{client.adresse}</span></li>) : ""}
                                                    {client.code_postal ? (<li>Code postal <span className="price">{client.code_postal}</span></li>) : ""}
                                                    {client.complement_adresse ? (<li>Complément adresse <span className="price">{client.complement_adresse}</span></li>) : ""}
                                                    {client.remarques ? (<li>Remarques <span className="price">{client.remarques}</span></li>) : ""}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {(cartItems.remarque && has_products) ? (
                                    <div className="clearfix">
                                        <div className="separateur"></div>
                                        <div className="cart-item">
                                            <div className="cart-content h-auto">
                                                <h4 className="cart-title">Remarque sur la commande</h4>
                                                <ul className="sub-items list-unstyled small">
                                                    <li>{cartItems.remarque}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : ""}

                                {/* <img className="barcode d-none" src="https://www.databar-barcode.info/wp-content/uploads/2015/02/barcode-13.png" alt="code bar" /> */}
                            </div>

                        </div>

                        {has_products ? (
                            <div className="clearfix">
                                <span className="clear-cart"><img src={require('../../assets/images/delete.svg')} alt="" height="24" /></span>
                                {cartItems.command_id ? (
                                    <div className="btn btn-payment btn-valid" id="btnValidateCommande" onClick={() => this.printTicket()}>
                                        Imprimer le ticket <span className="price"><img className="icon-arrow-right" src={require('../../assets/images/paiement.svg')} alt="Valider" height="35" /></span>
                                        {cartItems.ticket_html ? (<div className="d-none" id="tableTicketOrder">{cartItems.ticket_html}</div>) : ''}
                                    </div>
                                ) : (
                                    <div onClick={() => this.validateCommand()} className="btn btn-payment btn-valid" id="btnValidateCommande">
                                        Valider la commande <span className="price"><img className="icon-arrow-right" src={require('../../assets/images/left-arrow-white.svg')} alt="Valider" height="25" /></span>
                                    </div>
                                )}
                            </div>
                        ) : ""}


                    </div>

                    <div className={`actions last-prod-actions ${this.props.lastProduct.name ? 'can-show' : ''}`}>
                        <h2 className="title"><strong>{this.props.lastProduct.qte} x </strong> {this.props.lastProduct.name} {this.props.lastProduct.variant ? " - " + this.props.lastProduct.variant : ""}</h2>
                        <ul className="list-inline">
                            <li className="list-inline-item no-close" onClick={() => this.updateProduct(this.props.lastProduct, 'minus', lastProductIndex)}>
                                <div className="action-link"><img src={require("../../assets/images/minus-symbol.svg")} alt="minusSymbol" /></div>
                            </li>
                            <li className="list-inline-item no-close" onClick={() => this.updateProduct(this.props.lastProduct, 'plus', lastProductIndex)}>
                                <div className="action-link"><img src={require("../../assets/images/plus-symbol.svg")} alt="plusSymbol" /></div>
                            </li>
                            <li className="list-inline-item" onClick={() => this.personalizeProduct(this.props.lastProduct)}>
                                <div className="action-link">{this.props.lastProduct.is_menu ? "M" : this.props.lastProduct.is_personalizable ? "P" : ""}</div>
                            </li>
                            <li className="list-inline-item" onClick={() => this.reductionProduct(this.props.lastProduct)}>
                                <div className="action-link"><img src={require("../../assets/images/percentage.svg")} alt="percentageIcon" /></div>
                            </li>
                            <li className="list-inline-item" onClick={() => this.updateProduct(this.props.lastProduct, 'delete', lastProductIndex)}>
                                <div className="action-link"><img src={require("../../assets/images/delete.svg")} alt="deleteIcon" /></div>
                            </li>
                            <li className="list-inline-item delete" onClick={() => this.closeProduct()}>
                                <div className="action-link"><img src={require("../../assets/images/plus-symbol.svg")} alt="plusSymbol" /></div>
                            </li>
                        </ul>
                    </div>
                </aside>
            </React.Fragment>
        );
    }
}

export default (Sidebar);
