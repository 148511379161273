import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { config } from '../helpers/configHelper';
import { loadCart } from "../helpers/cartHelper";

class Client extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: config.client_fields,
            active_field: null,
            autosave: props.autosave || false,
        };
    }

    handleChange = (event, inp = null) => {
        const field = inp ? inp : this.state.active_field;

        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        const fields = this.state.fields;

        let index = fields.findIndex(item => item.name === field);
        let row = fields[index];
        row.value = val;

        this.setState({ fields: fields });

        if (this.state.autosave) {
            this.saveClient();
            if (typeof this.props.reloadCart === 'function') {
                this.props.reloadCart();
            }
        }
    }

    changeSearchHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ search_value: val });
    }

    saveClient = () => {
        const fields = this.state.fields;
        let isValid = true;

        let data = {};
        fields.forEach(field => {
            field.classError = null;
            if (field.required && !field.value) {
                field.classError = "is-invalid";
                isValid = false;
            }
            if (field.value) {
                data[field.name] = field.value;
            }
        });

        this.setState({ fields: fields });

        if (isValid) {
            const cart = loadCart().get() || {};
            cart.client = data;
            loadCart().persist(cart);

            if (this.props.refer === "home") {
                this.props.history.push(`/categories`);
            }
            if (typeof this.props.setModal === 'function') {
                this.props.setModal();
                if (typeof this.props.reloadCart === 'function') {
                    this.props.reloadCart();
                }
            }
        }
    }

    showKeyboard = (event, handle = null) => {
        let target = event.target;
        this.setState({ active_field: target.name }, function () {
            if (typeof this.props.showKeyboard === 'function') {
                this.props.showKeyboard(1, handle, target);
            }
        });
    }

    render() {
        const fields = this.state.fields;
        return (
            <React.Fragment>
                <div className="row">

                    <div className="payment-input-price w4btns d-none">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.search_value}
                                onChange={(event) => this.changeSearchHandling(event)}
                                onClick={(event) => this.showKeyboard(event, this.changeSearchHandling)}
                            />
                            <div className="input-group-append">
                                <button className="btn" type="button"><img src={require("../../assets/images/search.svg")} alt="" /></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mb-2 mt-2 d-none"><hr /></div>

                    {fields.map(field =>
                        <div className={`form-group ${field.class ? field.class : 'col-sm-12'}`} key={field.label}>
                            <label>{field.label} <small className="text-danger">{field.required ? "*" : ""}</small></label>
                            {field.type === "textarea" ? (
                                <textarea
                                    className={`form-control ${field.classError}`}
                                    name={field.name}
                                    required={field.required}
                                    rows="3"
                                    value={field.value}
                                    onChange={(event) => this.handleChange(event, field.name)}
                                    onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                ></textarea>
                            ) : (
                                <input
                                    type="text"
                                    value={field.value}
                                    className={`form-control ${field.classError}`}
                                    name={field.name}
                                    required={field.required}
                                    onChange={(event) => this.handleChange(event, field.name)}
                                    onClick={(event) => this.showKeyboard(event, this.handleChange)}
                                />
                            )}
                        </div>
                    )}

                    {!this.state.autosave ? (
                        <div className="col-md-12">
                            <button type="button" className="btn btn-payment text-center" onClick={() => this.saveClient()}>
                                Continuer
                                <span className="right"><img className="icon-arrow-right" src={require("../../assets/images/left-arrow-white.svg")} alt="" height="25" /></span>
                            </button>
                        </div>
                    ) : ''}

                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Client);
