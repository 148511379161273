import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { clearCart, loadCart } from '../helpers/cartHelper';

class Confirmcommand extends Component 
{

    cancelCommand = () => {
        const cart = loadCart().get() || {};
        clearCart();
        if (typeof this.props.setTypeLivraison === 'function') {
           this.props.setTypeLivraison(cart.livraison, "clear-cart");
        }
        this.props.history.push(`/categories`);
        this.setModal();
    }

    continueCommand = () => {
        this.props.history.push(`/categories`);
        this.setModal();
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart();
        }
    }

    commandEnAttente = () => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(null, "mettre-en-attente");
        }
        this.setModal();
    }

    setModal = (val=0) => {
        if (typeof this.props.setModal === 'function') {
            this.props.setModal(val);
        }
    }

    reloadCart = () => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart();
        }
    }

    render() {
        return (
            <React.Fragment>
                <h4 className="home-title">- Confirmer l'action ?</h4>
                <div className="list-btns">
                    <div className="btn-app hx2" onClick={()=>this.cancelCommand()}>
                        <div className="img-container h-100p">Annuler la commande actuel et créer une nouvelle</div>
                    </div>
                    <div className="btn-app hx2" onClick={()=>this.continueCommand()}>
                        <div className="img-container h-100p">Reprendre la commande actuelle</div>
                    </div>
                    <div className="btn-app hx2" onClick={()=>this.commandEnAttente()}>
                        <div className="img-container h-100p">Mettre en attente</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Confirmcommand);
