import { loadCart } from "./cartHelper";
const clientCart = loadCart().get();
const client = clientCart ? clientCart.client : "";

export const config = {
    payment_methods: ["Espèce", "Carte bancaire", "Chèque", "Chèque Restaurant"],
    // livraisons: [ 
    //     { libelle: "Sur place", icon: "sur-place.svg", params: 0 },
    //     { libelle: "A emporter", icon: "emporter.svg", params: 0 },
    //     { libelle: "Livraison", icon: "livraison.svg", params: 1 }
    // ],
    client_fields: [
        {
            name: "nom",
            label: "Nom",
            type: "text",
            value: client ? client.nom : "",
            required: false,
            class: "col-sm-6"
        },
        // {
        //     name: "num",
        //     label: "Numéro client",
        //     type: "text",
        //     value: client ? client.num : "",
        //     required: false,
        //     class: "col-sm-3"
        // },
        // {
        //     name: "tva",
        //     label: "Numéro TVA",
        //     type: "text",
        //     value: client ? client.tva : "",
        //     required: false,
        //     class: "col-sm-3"
        // },
        {
            name: "email",
            label: "Adresse mail",
            type: "text",
            value: client ? client.email : "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "telephone1",
            label: "Téléphone 1",
            type: "text",
            value: client ? client.telephone1 : "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "telephone2",
            label: "Téléphone 2",
            type: "text",
            value: client ? client.telephone2 : "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "adresse",
            label: "Adresse",
            type: "text",
            value: client ? client.adresse : "",
            required: false,
            class: "col-sm-12"
        },
        {
            name: "code_postal",
            label: "Code postal",
            type: "text",
            value: client ? client.code_postal : "",
            required: false,
            class: "col-sm-3"
        },
        {
            name: "complement_adresse",
            label: "Complément adresse",
            type: "text",
            value: client ? client.complement_adresse : "",
            required: false,
            class: "col-sm-9"
        },
        {
            name: "remarques",
            label: "Remarques",
            type: "textarea",
            value: client ? client.remarques : "",
            required: false,
            class: "col-sm-12"
        },
        // { 
        //     name: "points",
        //     label: "Point cumulés", 
        //     type: "text", 
        //     value: "",
        //     required: false, 
        //     class:"col-sm-3" 
        // },
    ],
    pagination: {
        perPage: 20
    },
    product_fields: [
        {
            name: "title",
            label: "Intitulé",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-12"
        },
        {
            name: "price_ht",
            label: "Prix HT",
            type: "number",
            value: "",
            required: true,
            class: "col-sm-6"
        },
        {
            name: "tva",
            label: "TVA",
            type: "number",
            value: "",
            required: true,
            class: "col-sm-6"
        },
        {
            name: "code",
            label: "Code barre",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-6"
        },
        {
            name: "reference",
            label: "Référence",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "description",
            label: "Unité/colis",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "stock",
            label: "Stock",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-6"
        },
    ],
    facture_client_fields: [
        {
            name: "client_name",
            label: "Raison sociale / Nom",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-12"
        },
        {
            name: "address",
            label: "Adresse",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-12"
        },
        {
            name: "postalcode",
            label: "Code postal",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-6"
        },
        {
            name: "city",
            label: "Ville",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-6"
        },
        {
            name: "phone",
            label: "Téléphone",
            type: "text",
            value: "",
            required: true,
            class: "col-sm-6"
        },
        {
            name: "client_email",
            label: "E-mail",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "client_tva",
            label: "N° TVA",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "client_siret",
            label: "Siret",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-6"
        },
        {
            name: "facturation_address",
            label: "Adresse de Facturation",
            type: "text",
            value: "",
            required: false,
            class: "col-sm-12"
        },
        {
            name: "sale_date",
            label: "La date de la vente",
            type: "text",
            placeholder: "dd-mm-yyyy",
            value: "",
            required: false,
            class: "col-sm-12"
        },
    ]
};
