import React, { Component } from 'react';
import { loadCart } from "../helpers/cartHelper";

class Selection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            compositions: [],
            ingredients: []
        };
    }

    componentWillMount() {
        this.loadItems();
    }

    componentWillReceiveProps() {
        this.loadItems();
    }

    removeItem(item, op) {
        if (op === "composition") {
            if (typeof this.props.updateComposition === 'function') {
                this.props.updateComposition(item);
            }

        } else if (op === "ingredient") {
            if (typeof this.props.updateIngredient === 'function') {
                this.props.updateIngredient(item);
            }
        }

    }

    loadItems() {
        const cartItems = (loadCart().get() || {})
        const products = Object.values(cartItems.products);
        const product = this.props.product;
        products.map(p => {
            if (p.uid === product.uid) {
                this.setState({
                    compositions: p.composition,
                    ingredients: p.ingredients
                });
            }
            return true;
        })
    }

    render() {
        const product = this.props.product;
        const compositions = this.state.compositions;
        const ingredients = this.state.ingredients;

        return (
            <React.Fragment>
                <fieldset className="pop-fieldset" key={Math.random()}>
                    <legend>{product.name} - {product.variant}</legend>
                    <div className="list-btns">
                        {compositions.map(comp =>
                            <div className={`btn-app active`} key={Math.random()} onClick={() => this.removeItem(comp, 'composition')}>
                                <div className="img-container h-100p">Sans {comp}</div>
                            </div>
                        )}

                        {ingredients.map(ingredient =>
                            <div className={`btn-app active`} key={Math.random()} onClick={() => this.removeItem(ingredient, 'ingredient')}>
                                <div className="img-container">{ingredient.title}</div>
                                <h4 className="btn-title item-price">{ingredient.price}€</h4>
                            </div>
                        )}
                    </div>
                </fieldset>
            </React.Fragment>
        );
    }
}

export default Selection;
