import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class Commandetat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            command: props.command_selected,
            active_etat: props.command_selected.order_statut
        };
    }

    componentWillMount() {
        window.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleClickOutside = (e) => {
        if (this.node.contains(e.target)) return;
        if (typeof this.props.showCommandEtat === 'function') {
            this.props.showCommandEtat(0);
        }
    }

    getActiveEtat = (etat) => { return this.state.active_etat === etat ? " disabled" : "" }

    changeEtatCommand = (cmd, etat) => {
        if (etat === this.state.active_etat) return false;

        if (etat === 'facture') {
            this.props.showCommandEtat(0);
            this.props.history.push(`/facture/${cmd.id}`);
            // return;
        } else {
            cmd.new_statut = etat;
            if (typeof this.props.changeEtatCommand === 'function') {
                this.props.changeEtatCommand(cmd);
            }
        }
    }

    render() {
        const command = this.props.command_selected;
        return (
            <React.Fragment>
                <div id="commandEtat" ref={node => this.node = node}>
                    <div className="closeKeyboard"><button className="btn" onClick={() => this.props.showCommandEtat(0)}><img className="icon-arrow-right" src={require('../../assets/images/x.svg')} alt="" /></button></div>
                    <div id="commandEtatContent">
                        {command ? (<h4>Commande N°{command.id}</h4>) : ""}
                        {/* <div className={`btn btn-payment${this.getActiveEtat("waiting")}`} onClick={() => this.changeEtatCommand(command, "waiting")}>À valider</div> */}
                        <div className={`btn btn-payment${this.getActiveEtat("progress")}`} onClick={() => this.changeEtatCommand(command, "progress")}>En cours</div>
                        <div className={`btn btn-payment${this.getActiveEtat("ready")}`} onClick={() => this.changeEtatCommand(command, "ready")}>Prête</div>
                        <div className={`btn btn-payment${this.getActiveEtat("delivery")}`} onClick={() => this.changeEtatCommand(command, "delivery")}>En livraison</div>
                        <div className={`btn btn-payment${this.getActiveEtat("completed")}`} onClick={() => this.changeEtatCommand(command, "completed")}>Terminée</div>
                        <div className={`btn btn-payment${this.getActiveEtat("cancel")}`} onClick={() => this.changeEtatCommand(command, "cancel")}>Annuler</div>
                        <div className={`btn btn-payment${this.getActiveEtat("facture")}`} onClick={() => this.changeEtatCommand(command, "facture")}>Facturer</div>
                        {/* <Link to={`/facture/${command.id}`} replace className={`btn btn-payment${this.getActiveEtat("facture")}`}>Facturer</Link> */}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Commandetat);
