import { config } from './configHelper';

export const api_url = "https://manager.superresto.ma/vitasuncaisse/api/public/api/";
export const api_url_login = "https://manager.superresto.ma/vitasuncaisse/api/public/login";

export function authUser() {
	const key = 'superRestoUser';

	return {
		persist: (data) => localStorage.setItem(key, JSON.stringify(data)),
		get: () => JSON.parse(localStorage.getItem(key)),
	}
}

export function logout() {
	localStorage.removeItem("superRestoUser");
}

export function apiListing() {
	const key = 'superRestoApiListing';
	return {
		persist: (data) => localStorage.setItem(key, JSON.stringify(data)),
		get: () => JSON.parse(localStorage.getItem(key)),
	}
}

export function loadCart() {
	const key = 'cartSuperResto';
	return {
		persist: (data) => localStorage.setItem(key, JSON.stringify(data)),
		get: () => JSON.parse(localStorage.getItem(key)),
	}
}

// export function loadCartLocal() {
//     const key = 'cartLocalSuperResto';
//     return {
//         persist: (data) => localStorage.setItem(key, JSON.stringify(data)),
//         get: () => JSON.parse(localStorage.getItem(key)),
//     }
// }

// export function loadCartLivraison() {
//     const key = 'livraisonSuperResto';
//     return {
//         persist: (data) => localStorage.setItem(key, JSON.stringify(data)),
//         get: () => JSON.parse(localStorage.getItem(key)),
//     }
// }

export function clearCart() {
	localStorage.removeItem("cartSuperResto");
	localStorage.removeItem("cartLocalSuperResto");
	//localStorage.removeItem("livraisonSuperResto");
}

// export function updateCart(product=null, operation="+", item=null) {
//     const cartLocal = loadCartLocal().get();
//     const items = cartLocal ? cartLocal.products : [];
//     let productInCart = false;

//     if ( product ) {
//         items.forEach(p => {
//             if (p.uid === product.uid) {
//                 productInCart = true;
//                 switch (operation) {

//                     case '-':
//                     if ( p.quantity<=1 ) p.quantity=1;
//                     else p.quantity -= 1;
//                     productInCart = true;
//                     break;

//                     case 'delete':
//                     const index = items.findIndex(p => (p.uid === product.uid));
//                     items.splice(index, 1);
//                     break;

//                     case '+':
//                     p.quantity += 1;
//                     break;

//                     case 'ingredient':
//                     let ingredientInCart = false;
//                     p.ingredients.forEach(ing => {
//                         if ( item.id === ing.id ) {
//                             const ing_index = p.ingredients.findIndex(ing => (ing.id === item.id));
//                             p.ingredients.splice(ing_index, 1);
//                             ingredientInCart = true;
//                         }
//                     });
//                     if ( !ingredientInCart ) p.ingredients.push(item);
//                     break;

//                     case 'composition':
//                     let compositionInCart = false;
//                     p.composition.forEach(comp => {
//                         if ( comp === item ) {
//                             const ing_index = p.composition.findIndex(comp => (comp === item));
//                             p.composition.splice(ing_index, 1);
//                             compositionInCart = true;
//                         }
//                     });
//                     if ( !compositionInCart ) p.composition.push(item);
//                     break;

//                     case 'menu':
//                     let menuInCart = false;
//                     p.menus.forEach(menu => {
//                         if ( menu === item ) {
//                             const ing_index = p.menus.findIndex(menu => (menu === item));
//                             p.menus.splice(ing_index, 1);
//                             menuInCart = true;
//                         }
//                     });
//                     if ( !menuInCart ) p.menus.push(item);
//                     break;

//                     case 'reduction':
//                     p.reduction = item;
//                     break;

//                     default:break;

//                 }
//             }
//         });

//         if (!productInCart) {
//             items.push({
//                 "uid": product.uid,
//                 "id"    :  product.id,
//                 "quantity"   :  1,
//                 "variant"  :  product.variant,
//                 "reduction"  :  [],
//                 "composition"  :  [],
//                 "ingredients"  :  [],
//                 "menus" : []
//             });
//         }

//     }


//     let cart = {};
//     cart["command"] = loadCartLivraison().get();
//     cart["products"] = items;
//     cart["user_id"] = authUser().get().id;

//     loadCartLocal().persist(cart);

//     return encodeURIComponent(JSON.stringify(cart));
// }

export function paginate(rows, current_rows = [], nav = "next") {
	const count = rows.length - 1;
	const perPage = config.pagination.perPage;
	// const nbrPages = Math.round(count/perPage);

	let row = current_rows[0];

	let index = rows.findIndex(item => item === row);

	if (index < 0) {
		index = 0;
	} else {
		if (nav === 'prev') {
			index = parseFloat(index) - perPage;
		} else {
			index = parseFloat(index) + perPage;
		}
	}

	const data = rows.slice(index, index + perPage);
	return {
		"data": data,
		"next_button": !(rows[count] === data[data.length - 1]),
		"prev_button": !(rows[0] === data[0]),
	};
}

export function getEtatLibelle(etat) {
	let lib = null;
	switch (etat) {
		case "waiting": lib = "À valider"; break;
		case "progress": lib = "En cours"; break;
		case "ready": lib = "Prête"; break;
		case "delivery": lib = "En livraison"; break;
		case "completed": lib = "Terminée"; break;
		case "cancel": lib = "Annulée"; break;
		default: break;
	}
	return lib;
}

export function dateFormat(date) {
	let dte = new Date(date);
	let dd = dte.getDate();
	var mm = dte.getMonth() + 1;
	var yyyy = dte.getFullYear();
	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;
	return dd + '-' + mm + '-' + yyyy
}