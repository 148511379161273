import React, { Component } from 'react';

class Composition extends Component 
{

    updateComposition = (composition) => {

        if (typeof this.props.updateComposition === 'function') {
            this.props.updateComposition(composition);
        }

        // const product = this.props.product;

        // let compositionInCart = false;
        // product.composition.forEach(comp => {
        //     if ( comp === composition ) {
        //         const ing_index = product.composition.findIndex(comp => (comp === composition));
        //         product.composition.splice(ing_index, 1);
        //         compositionInCart = true;
        //     }
        // });
        // if ( compositionInCart === false ) product.composition.push(composition);

        // if (typeof this.props.reloadCart === 'function') {
        //     this.props.reloadCart(product, 'add');
        // }
    }

    render() {
        const compositions = this.props.compositions;
        return (
            <fieldset className="pop-fieldset">
                <legend>Composition</legend>
                <div className="list-btns">
                    { compositions.map(comp =>
                        <div className={`btn-app ${ !this.props.product.composition.find(composition => composition === comp) ? 'active' : '' }`} key={Math.random()} onClick={() => this.updateComposition(comp)}>
                            <div className="img-container h-100p">{comp}</div>
                        </div>
                    )}
                </div>
            </fieldset>
        );
    }
}

export default Composition;
