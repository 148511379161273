import React, { Component } from 'react';
import "./messages.css";
import { withRouter } from "react-router-dom";
import { api_url } from "../helpers/cartHelper";
import Reclamations from "./Reclamations";
import Contactmessages from "./Contactmessages";
import Support from "./Support";

class Messages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user.user,
            messages: [],
            message_id: null,
            reponses: [],
            message_row: {},
            response_text: "",
            reclamations: [],
            contactmessages: [],
            tickets: [],
            active_tab: "reclamation",
            view: 0,
            form_message: true
        };
    }

    componentWillMount() {
        const user = this.state.auth_user;
        fetch(api_url + `messages/all?api_token=` + user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else {
                    this.setState({
                        reclamations: response.reclamations,
                        contactmessages: response.contactmessages,
                        tickets: response.tickets,
                    });
                }
            });
    }

    showMessage = (type, message) => {
        const user = this.state.auth_user;
        let params = { "type": type, "message": message };
        let url_params = encodeURIComponent(JSON.stringify(params));
        fetch(api_url + `messages/${url_params}?api_token=` + user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else {
                    this.setState({
                        message_id: message.id,
                        reponses: response.messages,
                        message_row: response.row,
                        form_message: response.form_message
                    });
                }
            });
    }

    changeResponseHandling = (event) => {
        let value = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ response_text: value });
    }

    showList = () => { this.setState({ message_id: null }); }

    sendResponse = () => {
        const user = this.state.auth_user;
        const message_row = this.state.message_row
        let params = {
            "type": this.state.active_tab,
            "row_id": message_row.id,
            "client_id": message_row.client_id,
            "response": this.state.response_text
        };
        let url_params = encodeURIComponent(JSON.stringify(params));
        fetch(api_url + `send-message/${url_params}?api_token=` + user.api_token)
            .then(results => { return results.json(); })
            .then(response => {
                if (response === "Unauthorized") {
                    this.logout();
                } else if (response.messages) {
                    this.setState({
                        reponses: response.messages,
                        response_text: "",
                    });
                }
            });
    }

    getActiveTab = (tab) => { return tab === this.state.active_tab ? " disabled" : ""; }
    setActiveTab = (tab) => { this.setState({ active_tab: tab, view: 0 }); }

    getActiveView = (view) => { return view === this.state.view ? " disabled" : ""; }
    setActiveView = (view) => { this.setState({ view: view }); }

    getTabContent = () => {
        switch (this.state.active_tab) {
            case "reclamation": return <Reclamations reclamations={this.state.reclamations} view={this.state.view} showMessage={this.showMessage} />;
            case "contact": return <Contactmessages contactmessages={this.state.contactmessages} view={this.state.view} />;
            case "ticket": return <Support tickets={this.state.tickets} view={this.state.view} showMessage={this.showMessage} showKeyboard={this.props.showKeyboard} auth_user={this.state.auth_user} reloadListTickets={this.reloadListTickets} />;
            default: return "";
        }
    }

    reloadListTickets = (data) => {
        this.setState({ tickets: data });
    }

    render() {
        const reponses = this.state.reponses;

        if (this.state.message_id) {
            return (
                <React.Fragment>
                    <div className="list-btns">
                        <div className="btn-app btn-app-auto" onClick={() => this.showList()}>
                            <div className="img-container">
                                <img className="icon" src={require("../../assets/images/left-arrow.svg")} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="chat clearfix">
                        {reponses.map(msg =>
                            <div className={`chat-message ${msg.float} clearfix`} key={Math.random()}>
                                <div className="message">
                                    <strong className="name">{msg.sender}</strong>
                                    <span className="text">{msg.message}</span>
                                    <small className="time">{msg.time}</small>
                                </div>
                            </div>
                        )}
                    </div>

                    {this.state.form_message ? (
                        <div className="input-area"><hr />
                            <div className="form-group">
                                <textarea
                                    value={this.state.response_text}
                                    onChange={this.changeResponseHandling}
                                    onClick={(event) => this.props.showKeyboard(event, this.changeResponseHandling)}
                                    className="form-control txt-message"
                                    rows="4"
                                    placeholder="Tapez votre message ici">
                                </textarea>
                            </div>
                            <button type="button" className="btn btn-payment text-center" onClick={() => this.sendResponse()}>
                                Envoyer
                                <span className="right"><img className="icon-arrow-right" src={require("../../assets/images/left-arrow-white.svg")} alt="" height="25" /></span>
                            </button>
                        </div>
                    ) : ""}

                </React.Fragment>
            );
        } else {

            const commandesReclamations = this.props.commandesReclamations ? this.props.commandesReclamations : [];
            const ticketsMessages = this.props.ticketsMessages ? this.props.ticketsMessages : [];
            const contactMessages = this.props.contactMessages ? this.props.contactMessages : [];

            return (
                <React.Fragment>

                    <div className="list-btns text-center">
                        <div className={`btn-app${this.getActiveTab('reclamation')}`} onClick={() => this.setActiveTab('reclamation')}>
                            <div className="img-container h-100p">Réclamations {commandesReclamations.length ? (<span className="nbr">({commandesReclamations.length})</span>) : ""}</div>
                        </div>
                        <div className={`btn-app${this.getActiveTab('contact')}`} onClick={() => this.setActiveTab('contact')}>
                            <div className="img-container h-100p">Demande de contact {contactMessages.length ? (<span className="nbr">({contactMessages.length})</span>) : ""}</div>
                        </div>
                        <div className={`btn-app${this.getActiveTab('ticket')}`} onClick={() => this.setActiveTab('ticket')}>
                            <div className="img-container h-100p">Support {ticketsMessages.length ? (<span className="nbr">({ticketsMessages.length})</span>) : ""}</div>
                        </div>
                    </div>
                    <div className="form-group text-center">
                        <div className="btn-group btn-group-etat btn-group-auto">
                            <button type="button" className={`btn btn-light${this.getActiveView(0)}`} onClick={() => this.setActiveView(0)}>{this.state.active_tab === "ticket" ? "Ouvert" : "Non lus"}</button>
                            <button type="button" className={`btn btn-light${this.getActiveView(1)}`} onClick={() => this.setActiveView(1)}>{this.state.active_tab === "ticket" ? "Fermé" : "Lus"}</button>
                        </div>
                    </div>

                    {this.getTabContent()}
                </React.Fragment>
            );
        }
    }
}

export default withRouter(Messages);
