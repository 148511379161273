import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { loadCart, clearCart, authUser } from "../helpers/cartHelper";
import Client from "./Client";

class Command extends Component {

    constructor(props) {
        super(props);
        const cart = loadCart().get() || {};
        this.state = {
            type_livraison: cart.livraison,
            delay: cart.livraison_delay ? cart.livraison_delay.value : "",
            remarque: cart.remarque ? cart.remarque : "",
            arr_delay: ["15min", "30min", "45min", "autres"],
            delay_input: (!cart.livraison_delay ? "" : cart.livraison_delay.libelle ? cart.livraison_delay.libelle : ""),
            modal_client: 0,
            auth_user: authUser().get(),
            cartItems: cart
        };
    }

    setLivraison = (liv) => {

        // vider le panier si une valide command est sélectionnée
        if (this.state.cartItems.command_id) {
            clearCart();
            if (typeof this.props.reloadCart === 'function') {
                this.props.reloadCart();
            }
        }

        if (typeof this.props.setTypeLivraison === 'function') {
            this.props.setTypeLivraison(liv);
        }

        if (this.props.refer === 'payment') {

            this.setState({
                type_livraison: liv,
                modal_client: liv.params
            });

        } else {
            // Check if cart is empty
            const cartItems = loadCart().get() || {};
            if (cartItems.products) {
                if (typeof this.props.setModal === 'function') {
                    this.props.setModal('confirmcommand');
                }
                return false;
            }

            if (!liv.params)
                this.props.history.push(`/categories`);
            else {
                if (typeof this.props.setModal === 'function') {
                    this.props.setModal('client');
                }
            }
        }
    }

    reloadCart = () => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart();
        }
    }

    changeDelayHandling = (event) => {
        let value = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ delay_input: value });
    }

    changeRemarqueHandling = (event) => {
        let value = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ remarque: value });
    }

    changeDelay = (val = null) => {
        this.setState({ delay: val });
    }

    saveCommand = () => {
        const cart = loadCart().get() || {};
        let lib = this.state.delay === "autres" ? this.state.delay_input : null;
        cart.livraison_delay = {
            value: this.state.delay,
            libelle: lib
        };
        cart.remarque = this.state.remarque;
        loadCart().persist(cart);

        if (typeof this.props.setModal === 'function') {
            if (this.state.modal_client) {
                this.props.setModal('client', this.props.refer);
            } else {
                this.props.setModal();
                this.reloadCart();
            }
        }
    }

    activeDelay = (delay) => { return this.state.delay === delay ? ' disabled' : ''; }

    activeTypeLivraison = (typ) => {
        const type_livraison = this.state.type_livraison ? this.state.type_livraison.title : null;
        return (type_livraison === typ && this.props.refer === 'payment') ? ' disabled' : '';
    }

    render() {
        const delivery_method = this.state.auth_user ? this.state.auth_user.restaurant.delivery_method : [];
        return (
            <React.Fragment>
                <div className="list-btns clearfix">
                    {delivery_method.map((liv) =>
                        <div className={`btn-app${this.activeTypeLivraison(liv.title)} bg-${liv.title_slug}`} key={liv.title} onClick={() => this.setLivraison(liv)}>
                            <div className="img-container">
                                <img className="icon" src={require(`../../assets/images/${liv.title_slug}.svg`)} alt="" />
                            </div>
                            <h4 className="btn-title">{liv.title}</h4>
                        </div>
                    )}
                </div>

                {this.props.refer === 'payment' ? (

                    <div className="clearfix">
                        <h4 className="home-title">- Délai de livraison</h4>
                        <div className="list-btns list-btns-align">
                            {this.state.arr_delay.map(delai =>
                                <div className={`btn-app${this.activeDelay(delai)}`} key={Math.random()} onClick={() => this.changeDelay(delai)}>
                                    <div className="img-container h-100p">{delai}</div>
                                </div>
                            )}
                        </div>

                        <div className={`payment-input-price m-0 w4btns${this.state.delay !== 'autres' ? ' d-none' : ''}`}>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.delay_input}
                                    onChange={this.changeDelayHandling}
                                    onClick={(event) => this.props.showKeyboard(event, this.changeDelayHandling)}
                                />
                            </div>
                        </div>

                        <div className="clearfix mt-3"></div>
                        <h4 className="home-title">- Remarque sur la commande</h4>
                        <textarea className="form-control" rows="5" value={this.state.remarque}
                            onChange={this.changeRemarqueHandling}
                            onClick={(event) => this.props.showKeyboard(event, this.changeRemarqueHandling)}
                        ></textarea>

                        <div className="clearfix mt-3">
                            <button type="button" className="btn btn-payment text-center" onClick={() => this.saveCommand()}>
                                Continuer
                                <span className="right"><img className="icon-arrow-right" src={require("../../assets/images/left-arrow-white.svg")} alt="" height="25" /></span>
                            </button>
                        </div>

                    </div>
                ) : ""}

                {this.state.show_params ? <Client /> : ''}

            </React.Fragment>
        );
    }
}

export default withRouter(Command);
