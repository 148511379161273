import React, { Component } from 'react';

class Contactmessages extends Component 
{
    render() {
        const messages = this.props.contactmessages;
        return (
            <fieldset className="pop-fieldset">
                <legend>Demande de contact</legend>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Email</th>
                            <th>Sujet</th>
                            <th>Date</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {messages.map(msg => 
                            <tr key={Math.random()} className={msg.view===this.props.view?"":"d-none"}>
                                <td>{ msg.fullname }</td>
                                <td>{ msg.email }</td>
                                <td>{ msg.subject }</td>
                                <td>{ msg.created_at }</td>
                                <td>{ msg.message }</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </fieldset>
        );
    }
}

export default Contactmessages;
