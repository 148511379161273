import React, { Component } from 'react';

class Commandgrid extends Component {

    toggleSidebar() {
        let sidebar = document.getElementById("sidebar");
        sidebar.classList.add("is-visible");
        /*let lastHash = window.location.hash;*/
        window.onhashchange = () => {
            /*window.location.hash = lastHash;*/
            sidebar.classList.remove("is-visible");
            window.onhashchange = null;
        };
    }

    render() {
        const commands = this.props.commands;
        return (
            <div id="list-comm" className="list-btns">
                {commands.map((command) =>
                    <div className="btn-app-1" key={command.id}>
                        <div className={`btn-app hx2`} onClick={() => { this.props.loadCommand(command); this.toggleSidebar() }}>
                            <div className="img-container">
                                <div>Commande N°: {command.id}</div>
                                <div>Date: {command.date_order}</div>
                            </div>
                            <h4 className="btn-title">{parseFloat(command.total).toFixed(2)}€</h4>
                        </div>
                        {this.props.hide_button ? "" : (
                            <button className="btn btn-info btn-block" onClick={() => this.props.showCommandEtat(command)}>Actions</button>
                        )}
                    </div>
                )}
            </div>
        )
    }

}

export default (Commandgrid);