import React, { Component } from 'react';

class Reduction extends Component {

    constructor(props) {
        super(props);
        const product = this.props.selectedProduct;
        this.state = {
            reduction: product.reduction ? product.reduction.value : 0,
            reduction_type: product.reduction ? product.reduction.type : "%",
            reduction_libelle: product.reduction ? product.reduction.libelle : "Réduction",
            product: product,
            total: product.price_ht,
            total_global: product.price_ht,
        };
    }

    showKeyboard = (event, handle) => {
        if (typeof this.props.showKeyboard === 'function') {
            this.props.showKeyboard(1, handle, event.target);
        }
    }

    changeCalculeMethod = () => {
        this.setState({ reduction_type: (this.state.reduction_type === "%" ? "€" : "%") }, function () { this.getTotal() });
    }

    reductionLibelleHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ reduction_libelle: val });
    }

    changeReductionHandling = (event) => {
        let val = (typeof event.target !== 'undefined') ? event.target.value : event;
        this.setState({ reduction: val }, function () { this.getTotal() });
    }

    getTotal = () => {
        let total = this.state.total_global;
        const reduction = parseFloat(this.state.reduction);
        if (reduction >= 0) {
            let reduc = this.state.reduction_type === "%" ? ((total * reduction) / 100) : reduction;
            total -= reduc;
        }
        this.setState({ total: parseFloat(total).toFixed(2) });
    }

    applyReduction = () => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(this.state.product, 'reduction', {
                "libelle": this.state.reduction_libelle,
                "value": this.state.reduction,
                "type": this.state.reduction_type,
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                {/* <div className="price-total w4btns m-0">{this.state.total}€</div> */}
                <div className="w4btns m-0">
                    <div className="payment-input-price">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={this.state.reduction_libelle}
                                onChange={this.reductionLibelleHandling}
                                onClick={(event) => this.showKeyboard(event, this.reductionLibelleHandling)}
                            />
                            <input
                                type="number"
                                className="form-control input-value"
                                value={this.state.reduction}
                                onChange={this.changeReductionHandling}
                                onClick={(event) => this.showKeyboard(event, this.changeReductionHandling)}
                            />
                            <div className="input-group-append">
                                <button className="btn" type="button" onClick={() => this.changeCalculeMethod('reduction')}>-{this.state.reduction + this.state.reduction_type}</button>
                                <button className="btn bg-dark sm-btn" type="button" onClick={() => this.applyReduction()}>
                                    <img className="icon-arrow-right" src={require('../../assets/images/left-arrow-white.svg')} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }

}

export default Reduction;
