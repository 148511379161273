import React, { Component } from 'react';
import './personnaliser.css';
import Composition from './Composition';
import Ingredients from './Ingredients';
import Selection from './Selection';

class Personnaliser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            active_tab: null,
            arr_tab: [],
            product: props.product,
            ingredients: [],
            ingredients_all: [],
            menus: [],
            next_button: false,
            prev_button: false,
            arr_tabs_menu: []
        };
    }

    componentDidMount() {
        let btn = document.querySelector("#root .pop-modal.pop-customisation .top-btns .btn-app");
        if (btn)
            btn.click();
    }

    reloadCart = (product=null, op=null) => {
        if (typeof this.props.reloadCart === 'function') {
            this.props.reloadCart(product, op);
        }
    }

    activeTab = (tab) => {
        return this.state.active_tab===tab ? ' disabled' : '';
    }

    changeTab = (tab, list=null) => {
        //let arr_tab[]
        this.setState({
            active_tab: tab,
            tab_changed: 1
        }, function(){
            if ( list ) {
                //const paginate_func = paginate(list);
                this.setState({
                    ingredients: list,
                    //ingredients_all: list,
                    //next_button: paginate_func.next_button,
                    //prev_button: paginate_func.prev_button,
                });
            }
        });
    }

    paginateIngredients = (ingredients, nav) => {
        //const paginate_func = paginate(this.state.ingredients_all, ingredients, nav);
        this.setState({
            ingredients: ingredients,
            //next_button: paginate_func.next_button,
            //prev_button: paginate_func.prev_button,
        });
    }

    updateComposition = (composition) => {
        const product = this.state.product;
        let compositionInCart = false;
        product.composition.forEach(comp => {
            if ( comp === composition ) {
                const ing_index = product.composition.findIndex(comp => (comp === composition));
                product.composition.splice(ing_index, 1);
                compositionInCart = true;
            }
        });
        if ( compositionInCart === false ) product.composition.push(composition);
        this.reloadCart(product, 'add');
    }

    updateIngredient = (ingredient) => {
        const product = this.state.product;
        let ingredientInCart = false;
        product.ingredients.forEach(ing => {
            if ( ingredient.ingredient_id === ing.ingredient_id ) {
                const ing_index = product.ingredients.findIndex(ing => (ing.ingredient_id === ingredient.ingredient_id));
                product.ingredients.splice(ing_index, 1);
                ingredientInCart = true;
            }
        });
        if ( !ingredientInCart ) product.ingredients.push(ingredient);
        this.reloadCart(product, 'add');
    }

    updateMenu = (menu) => {
        const product = this.state.product;
        if ( !product.menus ) product.menus = [];
        
        menu.tab = this.state.active_tab;

        let menuInCart = false;
        product.menus.forEach(mn => {
            if ( menu.tab === mn.tab ) {
                const ing_index = product.menus.findIndex(mn => (mn.tab === menu.tab));
                product.menus[ing_index] = menu;
                menuInCart = true;
            }
        });

        if ( !menuInCart ) product.menus.push(menu);
        this.reloadCart(product, "add");
    }
	
	closeModal = () => {
		let btnClose = document.querySelector("#root .pop-modal.pop-customisation .closeBtn");
		if (btnClose) btnClose.click();
	}

    render() {
        const ingredients = Object.entries(this.props.data.ingredients);
        const menus = Object.entries(this.props.data.menus);

        if ( this.state.product.is_menu ) {
            const ingredients = Object.values(this.state.ingredients);
            return (
                <React.Fragment>
                    <div className="list-btns text-center top-btns">
                        { menus.map(([menu, products]) =>
                            <div className={`btn-app ${this.activeTab(menu)} ${ this.state.product.menus && this.state.product.menus.find(men => men.tab === menu) ? 'is-ok' : '' }`} key={Math.random()} onClick={() => this.changeTab(menu, products)}>
                                <div className="img-container h-100p">{menu}</div>
                            </div>
                        )}
                    </div>
                    <hr/>
                    <fieldset className="pop-fieldset"  >
                        <legend>{this.state.active_tab}</legend>
                        <div className="list-btns">
                            { ingredients.map((menu) =>
                                <div className={`btn-app ${ this.state.product.menus && this.state.product.menus.find(men => men.name === menu.name) ? 'active' : '' }`} key={Math.random()} onClick={() => this.updateMenu(menu)}>
                                    <div className="img-container h-100p">{menu.name}</div>
                                </div>
                            )}
                        </div>
                    </fieldset>
					<hr/>
					<div className="row">
						<div className="col-sm-6">
							<button className="btn btn-success form-control" onClick={()=>this.closeModal()}><img src={require('../../assets/images/checked.svg')} alt="" height="25"/></button>
						</div>
						<div className="col-sm-6">
							<button className="btn btn-secondary form-control" onClick={() => this.reloadCart(this.state.product, 'delete')}><img src={require('../../assets/images/delete.svg')} alt="" height="25"/></button>
						</div>
					</div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="list-btns text-center top-btns">
                        <div className={`btn-app${this.activeTab('composition')}`} onClick={() => this.changeTab('composition')}>
                            <div className="img-container h-100p">Composition</div>
                        </div>
                        { ingredients.map(([category, products]) =>
                            <div className={`btn-app${this.activeTab(category)}`} key={Math.random()} onClick={() => this.changeTab(category, products)}>
                                <div className="img-container h-100p">{category}</div>
                            </div>
                        )}
                        <div className={`btn-app${this.activeTab('produits_choisi')}`} onClick={() => this.changeTab('produits_choisi')}>
                            <div className="img-container h-100p">Composition & Ingrédients choisi</div>
                        </div>
                    </div>

                    <div className="clearfix"><hr/>
                        {this.getTabContent()}
						
						<hr/>
						<div className="row">
							<div className="col-sm-6">
								<button className="btn btn-success form-control" onClick={()=>this.closeModal()}><img src={require('../../assets/images/checked.svg')} alt="" height="25"/></button>
							</div>
							<div className="col-sm-6">
								<button className="btn btn-secondary form-control" onClick={() => this.reloadCart(this.state.product, 'delete')}><img src={require('../../assets/images/delete.svg')} alt="" height="25"/></button>
							</div>
						</div>
                    </div>
                </React.Fragment>
            );
        }
    }

    getTabContent = () => {
        if ( this.state.active_tab === "composition" ) {
            return <Composition 
                        compositions={this.props.data.composition} 
                        updateComposition={this.updateComposition} 
                        product={this.state.product}
                    />
        } else if ( this.state.active_tab === "produits_choisi" ) {
            return <Selection 
                        product={this.state.product} 
                        cartItems={this.props.cartItems}
                        updateComposition={this.updateComposition} 
                        updateIngredient={this.updateIngredient}
                    />
        } else if ( this.state.active_tab ) {
            return <Ingredients 
                        ingredients={this.state.ingredients} 
                        product={this.state.product} 
                        category={this.state.active_tab} 
                        reloadCart={this.reloadCart} 
                        paginateIngredients={this.paginateIngredients} 
                        next_button={this.state.next_button} 
                        prev_button={this.state.prev_button} 
                        updateIngredient={this.updateIngredient}
                    />
        }
    }

}

export default Personnaliser;
