import React, { Component } from 'react';
import './header.css';
import { Link } from "react-router-dom";
import { loadCart, authUser } from "../helpers/cartHelper";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth_user: authUser().get(),
        };
    }

    componentDidMount() {
        let menuCategories = document.querySelector("#header .mobile-categories .list-btns");
        if (menuCategories) {
            menuCategories.addEventListener("click", () => {
                this.props.toggleMenu(false);
            })
        }

        let menuStates = document.querySelector("#header li.parent.bottom");
        if (menuStates) {
            menuStates.addEventListener("click", () => {
                this.props.toggleMenu(false);
            })
        }
    }

    setLivraison = (liv) => {
        if (typeof this.props.setTypeLivraison === 'function') {
            this.props.setTypeLivraison(liv, 'reload-cart');
        }
    }

    render() {

        const commandesEnAttente = this.props.commandesEnAttente ? this.props.commandesEnAttente : [];
        // const commandesAvalider = this.props.commandesAvalider ? this.props.commandesAvalider : [];
        const commandesEncours = this.props.commandesEnCours ? this.props.commandesEnCours : [];
        const commandesPrete = this.props.commandesPrete ? this.props.commandesPrete : [];
        const commandesEnLivraison = this.props.commandesEnLivraison ? this.props.commandesEnLivraison : [];
        const commandesTerminees = this.props.commandesTerminees ? this.props.commandesTerminees : [];
        const commandesAnnulees = this.props.commandesAnnulees ? this.props.commandesAnnulees : [];
        const commandesReclamations = this.props.commandesReclamations ? this.props.commandesReclamations : [];
        const ticketsMessages = this.props.ticketsMessages ? this.props.ticketsMessages : [];
        const contactMessages = this.props.contactMessages ? this.props.contactMessages : [];

        const messages = (commandesReclamations.length + ticketsMessages.length + contactMessages.length);

        const cart = loadCart().get() || {};
        const livraison = cart.livraison || {};

        const delivery_method = this.state.auth_user ? this.state.auth_user.restaurant.delivery_method : [];

        return (
            <div>
                <ul className="nav">

                    <li className="parent top">
                        <ul>
                            <li className="nav-item">
                                <Link to="/" replace className="nav-link nav-home"><img src={require('../../assets/images/home.svg')} alt="Home" style={{ height: '25px' }} /></Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/messages" replace className="nav-link nav-messages"><img src={require('../../assets/images/envelope.svg')} alt="Messages" style={{ height: '28px' }} /> {messages ? (<span className="nbr">({messages})</span>) : ""}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/produit" replace className="nav-link nav-home"><img src={require('../../assets/images/plus-symbol.svg')} alt="Messages" style={{ height: '28px' }} /> {messages ? (<span className="nbr">({messages})</span>) : ""}</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/search" replace className="nav-link nav-search"><img src={require('../../assets/images/search.svg')} alt="Search" style={{ height: '23px' }} /></Link>
                            </li>
                        </ul>
                    </li>

                    {(!cart.command_id && cart.products) ? (
                        <li className="parent top">
                            <ul>
                                {delivery_method.map((liv) =>
                                    <li className={`nav-item ${liv.title_slug === livraison.title_slug ? 'd-nonee' : ''}`} key={liv.title}>
                                        <div className={`nav-link bg-${liv.title_slug}`} title={liv.title} onClick={() => this.setLivraison(liv)}>
                                            <img src={require(`../../assets/images/${liv.title_slug}.svg`)} alt={liv.title} style={{ height: '40px' }} />
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </li>
                    ) : ''}

                    <li className="parent bottom">
                        <ul>
                            <li className="nav-item nav-text">
                                <Link to="/en-attente" replace className="nav-link">En attente {commandesEnAttente.length ? (<span className="nbr">{commandesEnAttente.length}</span>) : ""}</Link>
                            </li>
                            {/* <li className="nav-item nav-text">
                                <Link to="/a-valider" replace className="nav-link">À valider {commandesAvalider.length ? (<span className="nbr">{commandesAvalider.length}</span>) : ""}</Link>
                            </li> */}
                            <li className="nav-item nav-text">
                                <Link to="/en-cours" replace className="nav-link">En cours {commandesEncours.length ? (<span className="nbr">{commandesEncours.length}</span>) : ""}</Link>
                            </li>
                            <li className="nav-item nav-text">
                                <Link to="/prete" replace className="nav-link">Prêtes {commandesPrete.length ? (<span className="nbr">{commandesPrete.length}</span>) : ""}</Link>
                            </li>
                            <li className="nav-item nav-text">
                                <Link to="/en-livraison" replace className="nav-link">En livraison {commandesEnLivraison.length ? (<span className="nbr">{commandesEnLivraison.length}</span>) : ""}</Link>
                            </li>
                            <li className="nav-item nav-text">
                                <Link to="/annulees" replace className="nav-link">Annulées {commandesAnnulees.length ? (<span className="nbr">{commandesAnnulees.length}</span>) : ""}</Link>
                            </li>
                            <li className="nav-item nav-text">
                                <Link to="/terminees" replace className="nav-link">Terminées {commandesTerminees.length ? (<span className="nbr">{commandesTerminees.length}</span>) : ""}</Link>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
        );
    }
}

export default Header;
